/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('UnguidedProcedureController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'apiAdmin', 'CustomFieldFactory', 'HelperService', 'CommonService', 'AdminFactory', 'dialogs', 'GlobalDataFactory', 'CacheFactory',
        function($scope, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService,apiAdmin, CustomFieldFactory, HelperService, CommonService, AdminFactory, dialogs, GlobalDataFactory, CacheFactory) {
            $scope.showLoadingdiv = false;
            $scope.familyProcedures = GlobalDataFactory.familyProcedures;
            $scope.familiesProcedureTree = GlobalDataFactory.familyProceduresTree;
            $scope.classificationTree = GlobalDataFactory.archiveClassificationsTree;
            $scope.classificationBoxs = GlobalDataFactory.archiveClassifications;
            $scope.profilesProcedure = [];
            $scope.showClassificationList = apiAdmin.showClassificationList;
            $scope.expirationTypeList = apiAdmin.expirationTypeList;
            $scope.customFieldRequiredList = apiAdmin.customFieldRequiredList;
            $scope.showSubjectList = apiAdmin.showSubjectList;
            $scope.yesNoList = apiAdmin.yesNoBoolean;
            $scope.internalControl = false;
            if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
            	$scope.internalControl = true;
            }
            $scope.inspectionInterventionTypes = apiAdmin.inspectionInterventionTypes;
            $scope.yesNoBooleanNull = apiAdmin.yesNoBooleanNull;
            $scope.customFields = [];
            $scope.languageColumn = Language.getActiveColumn();
            $scope.descriptionLanguageColumn = "";
            $scope.informationLanguageColumn = "";
            $scope.accessLevelNotificationList = apiAdmin.accessLevelNotificationList;
            if($scope.languageColumn) {
                $scope.descriptionLanguageColumn = "descriptionLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
                $scope.informationLanguageColumn = "informationLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
            }
            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement('family', 'select-tree', 0, 'global.literals.family', undefined, GlobalDataFactory.familyProcedures, undefined, undefined, false, undefined, $scope.languageColumn);
            $scope.tfilter.addElement('id', 'text', 1, 'global.literals.code');
            $scope.tfilter.addElement('acronym', 'text', 2, 'global.literals.acronym');
            $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 3, 'global.literals.procedure');
            $scope.tfilter.loadLocalData([],undefined, undefined, $scope.app.language);

            $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, guided:false};

            $scope.dtDefUnguidedProcedure = new TableData('tram', 'Procedure', './api/tram/byfilter').setSortName('family').setSort([[1, 'asc']]);

            RestService.findAll('Profile').then(function(objects){
                var profiles = new SortedArray(JSOG.decode(objects.data), $scope.languageColumn).sort();
                $scope.profilesProcedure.length = 0;
                angular.forEach(profiles, function(value, key) {
                    $scope.profilesProcedure.push(value);
                });
            });

            CustomFieldFactory.getCustomFields().then(function (data) {
                if (data){
                    var response = JSOG.decode(data);
                    $scope.customFields.length = 0;
                    $scope.customFields.push({id:"-1", language1:$filter('translate')('global.literals.new'), language2:$filter('translate')('global.literals.new'), language3:$filter('translate')('global.literals.new')});
                    response.customFields = $linq(response.customFields).orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    angular.forEach(response.customFields, function(value, key){
                        var aux = HelperService.findFirstElementWhere(response.customFieldsDisable, 'id', value.id, -1);
                        if(aux != -1){
                            value.disableElement = true;
                        }
                        $scope.customFields.push(value);
                    });
                }
            });

            var FamilyColumn = new DatabaseTranslatedColumn($filter, 'global.literals.family', Language.getActiveColumn());
            var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn());
            var ProcedureActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see', 'annexa.admin.unguided_procedure.edit_unguided_procedure({ procedure: \'[data]\'})', 'fa-eye', true),
                [new ActionButton('global.literals.remove', 'deleteProcedure([data])')]);

            var getFilterCall = function(){
                var filterCall = $scope.tfilter.getFilterCall();
                if($scope.filterData.status && $scope.filterData.status != ''){
                    filterCall.docStatus = $scope.filterData.status;
                }
                if($scope.filterData.guided && $scope.filterData.guided != ''){
                    filterCall.guided = $scope.filterData.guided;
                }else if($scope.filterData.guided == false){
                    filterCall.guided = $scope.filterData.guided;
                }
                return filterCall;
            }

            $scope.tableUnguidedProcedure = {
                dtColumns: [
                    DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn('family.' + FamilyColumn.getColumn()).withTitle(FamilyColumn.getTitle()).renderWith(FamilyColumn.getRender),
                    DTColumnBuilder.newColumn('id').withTitle($filter('translate')('global.literals.code')),
                    DTColumnBuilder.newColumn('acronym').withTitle($filter('translate')('global.literals.acronym')),
                    DTColumnBuilder.newColumn(ProcedureColumn.getColumn()).withTitle(ProcedureColumn.getTitle()),
                    DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '130px').withTitle(ProcedureActionsColumn.getTitle()).renderWith(ProcedureActionsColumn.getRender).notSortable()
                ],
                filterCall: getFilterCall(),
                filterCallAux: {loggedUser: $rootScope.LoggedUser.id, applyPermissions: false, langColumn: $scope.languageColumn, admin: true },
                reLoadInternalData: function (resetPaging) {
                    this.filterCall = getFilterCall();
                    this.filterCallAux = $scope.tfilter.getFilterCallAux();
                    this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                    this.filterCallAux.applyPermissions = false;
                    this.filterCallAux.langColumn = $scope.languageColumn;
                    this.filterCallAux.admin = true;
                    if(this.table.reloadData) {
                        this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                    }
                }
            }

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.unguided_procedure') {
                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-unguidedProcedure','fa fa-plus','global.commonAdmin.modal.unguidedProcedure.titleNew',undefined,'openNewProcedure')
                    ];
                    var customFields = angular.copy(GlobalDataFactory.customFields);
                    $scope.customFields.length = 0;
                    $scope.customFields.push({id:"-1", language1:$filter('translate')('global.literals.new'), language2:$filter('translate')('global.literals.new'), language3:$filter('translate')('global.literals.new')});
                    customFields = $linq(customFields).orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    angular.forEach(customFields, function(value, key) {
                        $scope.customFields.push(value);
                    });
                }
            })

            HeaderService.changeState($state.current);

            $scope.deleteProcedure = function(id) {
                if(id) {
                    var dlg = dialogs.confirm(
                        $filter('translate')('global.literals.deleteProcedure'),
                        $filter('translate')('global.literals.confirmDeleteProcedureMessage'),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );

                    dlg.result.then(function (btn) {
                        CommonAdminService.deleteProcedure(id).then(function (data) {
                            $scope.doSearchFilter();
                            if(GlobalDataFactory.procedures){
                            	GlobalDataFactory.procedures.length = 0;
                            }else{
                            	GlobalDataFactory.procedures = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }).catch(function (error) {
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(error.message),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    }, function (btn) {
                    });


                }
            };

            $scope.openNewProcedure = function () {
                $state.transitionTo('annexa.admin.unguided_procedure.new_unguided_procedure');
            }

            $scope.doSearchFilter=function(queued) {
                $scope.tableUnguidedProcedure.reLoadInternalData(true);
            }
    }])
    .controller('NewUnguidedProcedureController',['$scope', '$stateParams', '$timeout', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'apiAdmin', 'HelperService', 'ErrorFactory', '$translate', 'TramFactory', 'AdminFactory', 'GlobalDataFactory', 'CacheFactory',
        function($scope, $stateParams, $timeout, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService, apiAdmin, HelperService, ErrorFactory, $translate, TramFactory, AdminFactory, GlobalDataFactory, CacheFactory) {
            $scope.alerts = [];
            $scope.closeAlert = function (index) {
                $scope.alerts.splice(index, 1);
            }

            $scope.formEUP = new AnnexaFormly();
            $scope.customFieldsProcedure = [];
            HeaderService.onChangeState($scope, function(message) {
                if(message.state.name == 'annexa.admin.unguided_procedure.new_unguided_procedure') {
                    $rootScope.subHeadButtons = [
                        new HeadButton('btn grey-500 text-white breadDivButton ', undefined, '#admin-newUunguidedProcedure',undefined,'global.literals.cancel',undefined,'cancel'),
                        new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#admin-newUunguidedProcedure','fa-floppy-o','global.literals.save',undefined,'save')
                    ];
                }
            });
            HeaderService.changeState($state.current);

            $scope.save = function() {
                $timeout(function () {
                    angular.element('#smFormEUP').trigger('click');
                })
            };
            $scope.transactionTypes = [];
            if(GlobalDataFactory.transactiontypes && GlobalDataFactory.transactiontypes.length > 0){
                $scope.transactionTypes = $linq(GlobalDataFactory.transactiontypes).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).where("x => x.startType == true && x.active == 1").toArray();
            }
            $scope.tramitationTypes = GlobalDataFactory.dossierTramitationTypes;

            var validateProcedureClassifications = function() {
                var ret = true;

                var removeAlert = function() {
                    var indexAlert = $linq($scope.alerts).indexOf("x => x.msg == 'global.errors.procedureClassifications'");

                    if(indexAlert != -1) {
                        $scope.alerts.splice(indexAlert, 1);
                    }
                };

                var addAlert = function () {
                    var indexAlert = $linq($scope.alerts).indexOf("x => x.msg == 'global.errors.procedureClassifications'");

                    if(indexAlert == -1) {
                        $scope.alerts.push({ msg: 'global.errors.procedureClassifications' })
                    }
                };

                if($scope.formEUP.model.showClassification != 'DISABLED') {
                    if (!$scope.formEUP.model.procedureClassifications) {
                        ret = false;
                        addAlert();
                    } else if($scope.formEUP.model.procedureClassifications.length == 0) {
                        ret = false;
                        addAlert();
                    } else {
                        removeAlert();
                    }
                } else {
                    removeAlert();
                }

                return ret;
            }

            $scope.submitEUP = function () {
                var validPC = validateProcedureClassifications();

                if(validPC && $scope.formEUP.form && $scope.formEUP.form.$error && $scope.formEUP.form.$error.required && $scope.formEUP.form.$error.required.length == 1) {
                    $scope.formEUP.form.$error.required[0].$setValidity('required', true);
                }

                if ($scope.formEUP.form.$valid) {
                    var newProcedure = new procedure($scope.formEUP.model, true);
                    if(newProcedure.archiveClassification && newProcedure.archiveClassification.id && newProcedure.archiveClassification.id.$selected){
                        newProcedure.archiveClassification.id = newProcedure.archiveClassification.id.$selected.id;
                    }else{
                        newProcedure.archiveClassification = null;
                    }
                    if(newProcedure.family && newProcedure.family.id && newProcedure.family.id.$selected) {
                        newProcedure.family.id = newProcedure.family.id.$selected.id;
                    } else {
                        newProcedure.family = null;
                    }
                    var customFields = [];
                    angular.forEach($scope.customFieldsProcedure,function(value, key) {
                        var field = {
                            customField: value.customField,
                            required: value.requiredString == 'REQUIRED',
                            requiredForEndDossier: value.requiredString == 'REQUIRED_TO_END',
                            hiddenField: value.requiredString == 'HIDDEN_FIELD',
                            noEditable: value.requiredString == 'NO_EDITABLE',
                            viewOrder: value.viewOrder,
                            value: value.value
                        };
                        customFields.push(field);
                    });
                    var currentLanguage = $translate.use();
                    var lang1 = Language.getByColumn('language1');
                    var lang2 = Language.getByColumn('language2');
                    var lang3 = Language.getByColumn('language3');
                    var trans = {};
                    $translate.use(lang1).then(function (translation) {
                        trans.tranLanguage1 = $translate.instant('global.literals.procedure_transaction_title');
                        $translate.use(lang2).then(function (translation) {
                            trans.tranLanguage2 = $translate.instant('global.literals.procedure_transaction_title');
                            $translate.use(lang3).then(function (translation) {
                                trans.tranLanguage3 = $translate.instant('global.literals.procedure_transaction_title');
                                $translate.use(currentLanguage);
                                CommonAdminService.insertUnguidedProcedure(newProcedure, customFields, trans)
                                    .then(function(data) {
                                    	if(GlobalDataFactory.procedures){
                                        	GlobalDataFactory.procedures.length = 0;
                                        }else{
                                        	GlobalDataFactory.procedures = [];
                                        }
                                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                                        }
                                        GlobalDataFactory.loadGlobalData();
                                        $state.transitionTo('annexa.admin.unguided_procedure');
                                }).catch(function (error) {
                                    $translate.use(currentLanguage);
                                });
                            }).catch(function (error) {
                                $translate.use(currentLanguage);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                    })
                }
            }

            $scope.cancel = function() {
                $state.transitionTo('annexa.admin.unguided_procedure');
            };

            $scope.removeClassificationFuntion = function(index){
                if($scope.formEUP.model.procedureClassifications && $scope.formEUP.model.procedureClassifications.length > index){
                    $scope.formEUP.model.procedureClassifications.splice(index, 1);
                }
            };
            $scope.addClassificationFuntion = function(){
                 var id = 0;
                 if(this.optionAdd){
                    var classification = {};
                    classification[$scope.languageColumn] = this.optionAdd;
                    classification.deleted = false;
                    if($scope.formEUP.model.procedureClassifications){
                        $scope.formEUP.model.procedureClassifications.push(classification);
                    }else{
                        $scope.formEUP.model.procedureClassifications = [];
                        $scope.formEUP.model.procedureClassifications.push(classification);
                    }
                    this.optionAdd = '';
                }
            };

            var defaultExpirationDaysNotification = '';
            
            if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.notification_expiration_days
            		&& $rootScope.app.configuration.notification_expiration_days.value){
            	defaultExpirationDaysNotification = $rootScope.app.configuration.notification_expiration_days.value
            }
            
            $scope.procedure = {showClassification:"DISABLED", showSubject:"DISABLED", classification:{}, allowUserChangeMaxDays: false, active: true, expireType:"BUSINESS"};
            if(!$scope.procedure.procedureClassifications){
                $scope.procedure.procedureClassifications = [];
            }
            var data = {
                row: true,
                colClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
                labelClass: 'label-strong'
            };
            $scope.formEUP.model = new procedure($scope.procedure);
            if(!$scope.formEUP.model.archiveClassification) {
                $scope.formEUP.model.archiveClassification = {};
            }
            if(!$scope.formEUP.model.family) {
                $scope.formEUP.model.family = {};
            }
            $scope.formEUP.addField('language1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('language','','global.literals.name','horitzontal',true,false,true, $rootScope.app.languagedef),data);
            $scope.formEUP.addField('descriptionLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('descriptionLanguage','descriptionLanguage','global.literals.description','horitzontal',true,false,true,$rootScope.app.languagedef),data);
            $scope.formEUP.addField('family', 'annexaSelectTreeRow','', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.family', 'id', $scope.languageColumn,$scope.familiesProcedureTree,true, undefined, undefined, undefined, true), data);
            $scope.formEUP.addField('archiveClassification','annexaSelectTreeRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.classificationBox','id',$scope.languageColumn,$scope.classificationTree,$rootScope.esetMode, undefined, undefined, undefined, true),data);
            $scope.formEUP.addField('showClassification','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.showClassification','id','description',apiAdmin.showClassificationList,true),data);
            $scope.formEUP.addField('procedureClassifications','annexaLanguageFieldSet','',new AnnexaFormlyFieldFieldSetTemplateOptions('global.literals.classification',undefined,'','global.commonAdmin.modal.unguidedProcedure.placeholderClassification',$scope.addClassificationFuntion,$scope.removeClassificationFuntion,'',true, false),data,'model.showClassification == \'DISABLED\'');
            var affto = new AnnexaFormlyFieldTemplateOptions('text','global.literals.acronym',false,false);
            affto.maxlength = 20;
            $scope.formEUP.addField('active', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.active', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
            $scope.formEUP.addField('acronym','annexaInputRow','',affto,data);
            $scope.formEUP.addField('maxDays','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.literals.expire',true,false),data);
            $scope.formEUP.addField('expireType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.expireType','id','description',apiAdmin.expirationTypeList,true),data);
            $scope.formEUP.addField('expirationDaysNotification','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.literals.expirationDaysNotification',true,false),data,'',defaultExpirationDaysNotification);
            $scope.formEUP.addField('accessLevelNotification','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.accessLevelNotification','id','description',apiAdmin.accessLevelNotificationList,false),data);
            $scope.formEUP.addField('showSubject','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.showSubject','id','description',apiAdmin.showSubjectList,true),data);
            $scope.formEUP.addField('allowUserChangeMaxDays', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.allowUserChangeMaxDays', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
            $scope.formEUP.addField('transactionTypes','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.transactionType','id',Language.getActiveColumn(),$scope.transactionTypes,true),data);
            $scope.formEUP.addField('tramitationType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.tramitationType','id',Language.getActiveColumn(),$scope.tramitationTypes,true),data);
            var subFielsProfiles = [
                $scope.formEUP.createField('procedureStartProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialize','id',Language.getActiveColumn(),$scope.profilesProcedure,true, undefined, undefined, undefined, undefined, true),data),
                $scope.formEUP.createField('procedureViewProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.showProcedure','id',Language.getActiveColumn(),$scope.profilesProcedure,false, undefined, undefined, undefined, undefined, true),data)
            ];
            if(!$rootScope.esetMode){
                subFielsProfiles.push($scope.formEUP.createField('procedureResponsibleProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.responsible','id',Language.getActiveColumn(),$scope.profilesProcedure,false, undefined, undefined, undefined, undefined,true),data));
            }
            $scope.formEUP.addFieldGroup('','fileSetWrapper',new AnnexaFormlyFieldFieldSetTemplateOptions('global.literals.profiles',undefined,undefined,undefined,undefined,undefined,undefined,true, false),subFielsProfiles);
            $scope.formEUP.addField('informationLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('informationLanguage','informationLanguage','global.literals.information','horitzontal',false,false,true,$rootScope.app.languagedef),data);
            if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active){
	            $scope.formEUP.addField('operationsActive','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.activateOperationModule','id','description',apiAdmin.yesNoBoolean,true),data);
	            var operationTypeHiddenExpression = function($viewValue, $modelValue, scope) {
	            	return ((scope.model.operationsActive)?false:true);
	            }
	            $scope.formEUP.addField('operationTypes','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.operationTypes','id',Language.getActiveColumn(),GlobalDataFactory.operationTypes,false, undefined, undefined, undefined, undefined, true),data,operationTypeHiddenExpression);
            }
            $scope.formEUP.addField('inspectionIntervention', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.inspectionIntervention', 'id', 'description', apiAdmin.yesNoBooleanNull, false), data);
            var insIntTypeHiddenExpression = function($viewValue, $modelValue, scope) {
            	if($scope.internalControl && scope.model.inspectionIntervention) {
                    return false;
                } else {
                    return true;
                }
            }
            $scope.formEUP.addField('inspectionInterventionType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.inspectionInterventionType','id',"name",apiAdmin.inspectionInterventionTypes,true),data,insIntTypeHiddenExpression);
            
        }])
    .controller('EditUnguidedProcedureController',['$scope', '$stateParams', '$timeout', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'apiAdmin', 'HelperService', 'CustomFieldFactory','ErrorFactory', 'TramFactory', 'AdminFactory', 'globalModals', 'GlobalDataFactory', 'CacheFactory',
        function($scope, $stateParams, $timeout, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService, apiAdmin, HelperService, CustomFieldFactory, ErrorFactory, TramFactory, AdminFactory, globalModals, GlobalDataFactory, CacheFactory) {

            HeaderService.onChangeState($scope, function(message) {
                if(message.state.name == 'annexa.admin.unguided_procedure.edit_unguided_procedure') {
                    $rootScope.subHeadButtons = [];
                }
            });
            HeaderService.changeState($state.current);
            $scope.procedure = CustomFieldFactory.procedure;
            $scope.procedureStartProfilesAux = CustomFieldFactory.procedureStartProfilesAux;
            $scope.procedureViewProfilesAux =CustomFieldFactory.procedureViewProfilesAux;
            $scope.procedureResponsibleProfilesAux =CustomFieldFactory.procedureResponsibleProfilesAux;
            $scope.customFieldsProcedure = CustomFieldFactory.customFieldsProcedure;
            $scope.customFields = CustomFieldFactory.customFields;
            $scope.classificationBoxs = CustomFieldFactory.classificationBoxs;
            $scope.transactionTypesAux = CustomFieldFactory.transactionTypesAux;
            $scope.transactionTypes = [];
            if(GlobalDataFactory.transactiontypes && GlobalDataFactory.transactiontypes.length > 0){
                $scope.transactionTypes = $linq(GlobalDataFactory.transactiontypes).where("x => x.startType == true && x.active == 1").toArray();
            }
            $scope.tramitationTypes = GlobalDataFactory.dossierTramitationTypes;
            $scope.operationTypesAux = (($scope.procedure && $scope.procedure.operationTypes)?$linq($scope.procedure.operationTypes).where("x => x.operationType && x.operationType.id").select("x => x.operationType.id").toArray():[]);
            $scope.operationTypes = GlobalDataFactory.operationTypes;
            $scope.cancel = function() {
                $state.transitionTo('annexa.admin.unguided_procedure');
            };
            $scope.operationsActive = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active)?true:false);
            $scope.removeClassificationFuntion = function(index){
                if($scope.procedure.procedureClassifications && $scope.procedure.procedureClassifications.length > index){
                    $scope.procedure.procedureClassifications[index].deleted = true;
                    $scope.procedure.procedureClassifications[index].removedDate = new Date();
                    CommonAdminService.insertOrUpdateProcedureClassification($scope.procedure.procedureClassifications[index]).then(function(data) {
                        $scope.procedure.procedureClassifications.splice(index, 1);
                        if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        $scope.procedure.procedureClassifications[index].deleted = false;
                        $scope.procedure.procedureClassifications[index].removedDate = null;
                    });
                }
            };

            $scope.addClassificationFuntion = function(){
                var id = 0;
                if(this.optionAdd){
                    var classification = {};
                    classification.language1 = this.optionAdd;
                    classification.language2 = this.optionAdd;
                    classification.language3 = this.optionAdd;
                    classification.procedure = $scope.procedure;
                    CommonAdminService.insertOrUpdateProcedureClassification(classification).then(function(data) {
                        if($scope.procedure.procedureClassifications){
                            $scope.procedure.procedureClassifications.push(JSOG.decode(data));
                        }else{
                            $scope.procedure.procedureClassifications = [];
                            $scope.procedure.procedureClassifications.push(JSOG.decode(data));
                        }
                        if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        var a =0;
                    });
                    this.optionAdd = '';
                }
            };

            $scope.updateClassificationFuntion = function(index){
                if($scope.procedure.procedureClassifications[index]){
                    CommonAdminService.insertOrUpdateProcedureClassification($scope.procedure.procedureClassifications[index]).then(function(data) {
                        $scope.procedure.procedureClassifications[index] = JSOG.decode(data);
                        if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        var b = 0;
                    });
                }
            };

            $scope.getProfileValue = function(profiles){
                var prof = '';
                if(profiles){
                    angular.forEach(profiles, function(value, key){
                        var label = '';
                        angular.forEach($scope.profilesProcedure, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };

            $scope.getTransactionTypeValue = function(transactionTypes){
                var prof = '';
                if(transactionTypes){
                    angular.forEach(transactionTypes, function(value, key){
                        var label = '';
                        angular.forEach($scope.transactionTypes, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };
            
            $scope.openModalSelectMultiple = function(label, property, valuesAux) {
            	var modal = angular.copy(globalModals.selectMultipleCheckbox);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.model.modal_body.selectValues = {};
                modal.title = label;
                modal.property = property;
                
                var selectableOptions = [];
                _.forEach(this.profilesProcedure, function (value, key) {
                	selectableOptions.push({ id: value.id, value: value.language1});
                	modal.annexaFormly.model.modal_body.selectValues[value.id] = false;
                });
                _.forEach($scope[valuesAux], function (value, key) {
                    modal.annexaFormly.model.modal_body.selectValues[value] = true;
                });
                
                modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = selectableOptions;
                
                var submitFunction = function () {
                	var selectedOptions = [];
                	var selectOptions = modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options;
                	if(modal.annexaFormly.model.modal_body.selectValues){
                    	var keys = Object.keys(modal.annexaFormly.model.modal_body.selectValues);
                        if(keys && keys.length > 0){
                            _.forEach(keys, function(key){
                                if(modal.annexaFormly.model.modal_body.selectValues[key]){
                                	var sel = $linq(selectOptions).firstOrDefault(function (x) { return x.id == parseInt(key); });
                                	selectedOptions.push(sel.id);
                                }
                            });
                        }
                    }
                	$scope.updateProcedure(selectedOptions, modal.property);
                	$scope[valuesAux] = selectedOptions;
                	this.close();
                }
            	AnnexaFormlyFactory.showModal("selectMultipleCheckbox", modal, submitFunction, false);
            }

            $scope.getOperationTypeValue = function(operationTypes){
                var prof = '';
                if(operationTypes){
                    angular.forEach(operationTypes, function(value, key){
                        var label = '';
                        angular.forEach($scope.operationTypes, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };
            
            $scope.updateProcedure = function(data, property){
                var languages = $rootScope.app.languagedef;
                var model = angular.copy($scope.procedure);
                if(property != 'language' && property != 'descriptionLanguage' && property != 'informationLanguage' && property != 'transactionTypes' && property != 'procedureViewProfiles' && property != 'procedureStartProfiles' && property != 'procedureResponsibleProfiles' && property != 'archiveClassification' && property != 'family' && property != 'operationTypes' && property != 'inspectionIntervention' && property != 'inspectionInterventionType'){
                    if(property == 'maxDays' || property == 'expirationDaysNotification'){
                        if(data) {
                            model[property] = data;
                        }else{
                            return $filter('translate')('global.validation.required');
                        }
                    }else{
                        model[property] = data;
                    }
                }else if(property == 'procedureViewProfiles' || property == 'procedureStartProfiles' || property == 'procedureResponsibleProfiles') {
                    model[property] = procedureListObject(data, true, 'profile');
                }else if(property == 'transactionTypes'){
                    model[property] = procedureListObject(data, true, 'transactionType');
                }else if(property == 'operationTypes'){
                    model[property] = procedureListObject(data, true, 'operationType');
                } else if(property == 'language' || property == 'descriptionLanguage'){
                    if(languages){
                        var cont = 1;
                        var valid = true;
                        angular.forEach(languages, function (value, key) {
                           if (!model[property + cont]) {
                                valid = false;
                            }
                            cont++;
                        });
                    }
                    if(!valid){
                        return $filter('translate')('global.validation.required');
                    }
                }else if(property == 'informationLanguage'){
                    if(languages){
                        var cont = 1;
                        var valid = false;
                        angular.forEach(languages, function (value, key) {
                            if (cont == 1) {
                                valid = true;
                            }else if(!model[property + (cont-1)] && !model[property + cont] && valid){
                                valid = true
                            }else if(model[property + (cont-1)] && model[property + cont] && valid){
                                valid = true
                            }else{
                                valid = false;
                            }
                            cont++;
                        });
                        if(!valid){
                            return $filter('translate')('global.validation.required');
                        }
                    }
                }else if(property == 'archiveClassification'){
                    if(data.$selected && data.$selected.id){
                        model[property] = {id:data.$selected.id};
                    }else{
                        model[property] = {};
                    }
                } else if(property == 'family') {
                    if(data.$selected && data.$selected.id) {
                        model[property] = { id: data.$selected.id };
                    } else {
                        model[property] = {};
                    }
                } else if(property == 'inspectionIntervention') {
                     model[property] = data;
                     if(!data){
                    	 model.inspectionInterventionType = undefined;
                     }
                } else if(property == 'inspectionInterventionType') {
                    model[property] = data;
                }
                if(model.archiveClassification && model.archiveClassification.model){
                    if(model.archiveClassification.model.$selected && model.archiveClassification.model.$selected.id){
                        model.archiveClassification = {id:model.archiveClassification.model.$selected.id};
                    }else{
                        model.archiveClassification = undefined;
                    }
                }
                if(model.family && model.family.model) {
                    if(model.family.model.$selected && model.family.model.$selected.id) {
                        model.family = { id: model.family.model.$selected.id };
                    } else {
                        model.family = undefined;
                    }
                }
                CommonAdminService.updateUnguidedProcedure(model).then(function(data) {
                    var proc = JSOG.decode(data);
                    if(property == 'procedureViewProfiles'){
                        $scope.procedure.procedureViewProfiles = data.procedureViewProfiles;
                    }else if(property == 'procedureStartProfiles'){
                        $scope.procedure.procedureStartProfiles = data.procedureStartProfiles;
                    }else if(property == 'procedureResponsibleProfiles'){
                        $scope.procedure.procedureResponsibleProfiles = data.procedureResponsibleProfiles;
                    }
                    if(GlobalDataFactory.procedures){
                    	GlobalDataFactory.procedures.length = 0;
                    }else{
                    	GlobalDataFactory.procedures = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                    }
                    GlobalDataFactory.loadGlobalData();
                    var a = 0;
                }).catch(function (error) {
                    var b = 0;
                });
            }

            $scope.updateCustomField = function(customField){
                 CommonAdminService.insertOrUpdateTransactionCustomField(customField).then(function (data) {
                    var a = 0;
                    if(GlobalDataFactory.procedures){
                    	GlobalDataFactory.procedures.length = 0;
                    }else{
                    	GlobalDataFactory.procedures = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
                    var b = 0;
                });
            }

            $scope.createModalCustomField = function(transactionCustomField){
                var disabled = transactionCustomField.customField.disableElement;
               CommonAdminService.insertOrUpdateTransactionCustomField(transactionCustomField).then(function (data) {
                    transactionCustomField = JSOG.decode(data);
                    transactionCustomField.requiredString = "OPTIONAL";
                    transactionCustomField.customField.disableElement = disabled;
                    $scope.customFieldsProcedure.push(transactionCustomField);
                    $scope.procedure.transactionTransitions[0].nextTransaction.customFields.push(transactionCustomField);
                    if(GlobalDataFactory.procedures){
                    	GlobalDataFactory.procedures.length = 0;
                    }else{
                    	GlobalDataFactory.procedures = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                    }
                    GlobalDataFactory.loadGlobalData();
               }).catch(function (error) {
                    var b = 0;
               });

            };
            $scope.removeCustomFieldsFuntion = function(customField){
                var aux = HelperService.findFirstElementWhere($scope.procedure.transactionTransitions[0].nextTransaction.customFields, 'id', customField.id, -1);
                if(aux != -1){
                    aux.deleted = true;
                    aux.removedDate = new Date();
                    aux.required = customField.requiredString == 'REQUIRED';
                    aux.requiredForEndDossier = customField.requiredString == 'REQUIRED_TO_END';
                    aux.hiddenField =  customField.requiredString == 'HIDDEN_FIELD';
                    aux.noEditable = customField.requiredString == 'NO_EDITABLE';
                    aux.viewOrder = customField.viewOrder;
                    aux.value = customField.value;
                    CommonAdminService.insertOrUpdateTransactionCustomField(aux).then(function (data) {
                    	if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        var b = 0;
                    });
                }
            };

        }]);