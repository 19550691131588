angular
    .module('annexaApp')
        .controller('UserAccountingInstanceAdminController',['$scope', '$http', '$rootScope', 'CommonAdminModals', 'Language', '$filter', 'RestService', 'AnnexaModalFactory', 'AdminFactory', 'GlobalDataFactory', 'CacheFactory', 'globalModals', 'AnnexaFormlyFactory', 
        	function ($scope, $http, $rootScope, CommonAdminModals, Language, $filter, RestService, AnnexaModalFactory, AdminFactory, GlobalDataFactory, CacheFactory, globalModals, AnnexaFormlyFactory) {
    	 	$scope.newUserAccountingInstance = CommonAdminModals.userAccountingInstanceNew;
    	
    	 	$scope.saveUserAccountingInstance = function(){
    	 		if (this.annexaFormly.form.$valid) {
                	var self = this;
                	var data = this.annexaFormly.model.modal_body;
                	var sendData = {
                		code:((data.code)?data.code:undefined),
                		user:((data.user && data.user.id)?{id:data.user.id}:undefined),
                		userName:((data.userName)?data.userName:undefined),
                		managementCenter:((data.managementCenter)?data.managementCenter:undefined)
                	}
                    RestService.insert('./api/user_accounting_instance', sendData).then(function (dataGet) {
                    	$rootScope.$broadcast('annexaAdminBaseReloadTable');
                    	self.close();
                    }).catch(function (error) {
                    	self.alerts.push({ msg: $filter('translate')('global.userAccountingInstace.errorCreate') });
                    });
                }
            };
            
            $scope.filter = [
                { id: 'user', type: 'text', order: 1, label: 'global.literals.user', callAux: true },
                { id: 'code', type: 'text', order: 2, label: 'global.literals.codeType' },
                { id: 'userName', type: 'text', order: 3, label: 'global.userAccountingInstace.userName' }
            ]

            $scope.additionalColumns = [
            	{ id: 'user', column: new UserColumn($filter, 'global.literals.user', false) },
                { id: 'code', title: $filter('translate')('global.literals.codeType')},
                { id: 'userName', title: $filter('translate')('global.userAccountingInstace.userName')},
                { id: 'managementCenter', title: $filter('translate')('global.userAccountingInstace.managementCenter')}
            ]

            $scope.viewElement = function (id) {
                if(id) {
                	RestService.findOne('UserAccountingInstance', id).then(function(data) {
                        var modal = angular.copy(CommonAdminModals.userAccountingInstanceEdit);
                        modal.data = JSOG.decode(data.data);
                        modal.alerts = [];
                        modal.languageColumn = "name";
                        modal.accountInstances = AdminFactory.accountInstances;
                        modal.loadFunction = function(value) {
                            if(!value) {
                                return [];
                            }

                            if(value != '*' && value.length < 3) {
                                return [];
                            }

                            if(value == '*') {
                                value = '';
                            }

                            return RestService.loadData('common', 'User', value)
                                .then(function(data) {
                                    var users = [];

                                    if(data.data && data.data.content && data.data.content.length > 0) {
                                        _.forEach(JSOG.decode(data.data.content), function(val) {
                                            var name = val.name + ' ' + val.surename1;

                                            if(val.surename2) {
                                                name += ' ' + val.surename2;
                                            }

                                            users.push({ 'id':  val.id, 'user': val, 'value': name });
                                        });
                                    }

                                    return users;
                                }).catch(function() {
                                    return [];
                                });

                        }
                        var userAux = { user: undefined };
                        if(modal.data.user) {
                            var user_name = '';
                            user_name = modal.data.user.name + ' ' + modal.data.user.surename1 + (modal.data.user.surename2 ? ' ' + modal.data.user.surename2 : '');
                            userAux = { user: { id: modal.data.user.id, user: modal.data.user, value: user_name }};
                        }
                        modal.userAux = userAux;
                        modal.updateUserAccountingInstance = $scope.updateUserAccountingInstance;
                        AnnexaModalFactory.showModal('modalUserAccountingInstanceEdit', modal);
                    });
                }
            };
    	   
            $scope.updateUserAccountingInstance = function(val, prop) {
            	var userAccountingInstace = angular.copy(this.data);
            	var self = this;
            	self.alerts = [];
            	if(_.contains(['user'], prop)) {
                    if(val && val.user && val.user.id) {
                    	userAccountingInstace[prop] = { id: val.user.id };
                    } else {
                        return $filter('translate')('global.validation.required');
                    }
                } else if(_.contains(['code'], prop)) {
                    if(val) {
                    	userAccountingInstace[prop] = val.code;
                    } else {
                        return $filter('translate')('global.validation.required');
                    }
                } else {
                	userAccountingInstace[prop] = val;
                }
            	if(userAccountingInstace.code && userAccountingInstace.code.code){
            		userAccountingInstace.code = userAccountingInstace.code.code;
            	}
            	RestService.update('./api/user_accounting_instance/'+userAccountingInstace.id, userAccountingInstace).then(function (dataGetDocument) {
                	$rootScope.$broadcast('annexaAdminBaseReloadTable');
               }).catch(function (error) {
                	self.alerts.push({ msg: $filter('translate')('global.userAccountingInstace.errorCreate') });
                });
            };
            
        }]);