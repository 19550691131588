/**
* Created by mroman on 13/04/2018.
*/
angular
    .module('annexaApp')
    .controller('RegisterEntryOfficeController',['$scope', '$rootScope', '$filter', 'CommonAdminModals', 'Language', 'RestService', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'DccumentsFactory', 'CommonService', 'globalModals', 'GlobalDataFactory', 'CacheFactory', '$http',
        function ($scope, $rootScope, $filter, CommonAdminModals, Language, RestService, AnnexaFormlyFactory, AnnexaModalFactory, DccumentsFactory, CommonService, globalModals, GlobalDataFactory, CacheFactory, $http) {

        $scope.modelRegOffice;
    	
        $scope.newElement = function() {
            var modal = CommonAdminModals.newRegisterOffice;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalRegisterEntryOffice', modal, $scope.saveRegisterOffice, false)
        };

        $scope.saveRegisterOffice = function(registerOffice) {

            var modal = this;

            var profiles = [];

            _.forEach(registerOffice.annexaFormly.model.modal_body.profiles, function(item) {
                profiles.push({ profile: { id: item } });
            });

            var model = {
                language1: registerOffice.annexaFormly.model.modal_body.language1,
                language2: registerOffice.annexaFormly.model.modal_body.language2 ? registerOffice.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: registerOffice.annexaFormly.model.modal_body.language3 ? registerOffice.annexaFormly.model.modal_body.language3 : 'NotTranslated',

                acronym: registerOffice.annexaFormly.model.modal_body.acronym,
                profiles: profiles,

                registerEntryInputPattern: registerOffice.annexaFormly.model.modal_body.registerEntryInputPattern,
                registerEntryOutputPattern: registerOffice.annexaFormly.model.modal_body.registerEntryOutputPattern,
                registerEntryInputDigits: registerOffice.annexaFormly.model.modal_body.registerEntryInputDigits,
                registerEntryOutputDigits: registerOffice.annexaFormly.model.modal_body.registerEntryOutputDigits,

                registerEntryInputInitDossierDiligenceTypeMultiple: registerOffice.annexaFormly.model.modal_body.registerEntryInputInitDossierDiligenceTypeMultiple != undefined ? registerOffice.annexaFormly.model.modal_body.registerEntryInputInitDossierDiligenceTypeMultiple: false,
                registerEntryOutputNotificationChannel: { id: registerOffice.annexaFormly.model.modal_body.registerEntryOutputNotificationChannel },
                registerEntryInputChannel: { id: registerOffice.annexaFormly.model.modal_body.registerEntryInputChannel },
                registerEntryEditDays: registerOffice.annexaFormly.model.modal_body.registerEntryEditDays,
                registerEntryPending: registerOffice.annexaFormly.model.modal_body.registerEntryPending != undefined ? registerOffice.annexaFormly.model.modal_body.registerEntryPending: false
            };

            if (registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntrySupportingDocument && registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntrySupportingDocument.length > 0) {
                model.idDocumentManagerRegisterEntrySupportingDocument = JSON.stringify(registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntrySupportingDocument[0]);
            }

            if (registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntryBook && registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntryBook.length > 0) {
                model.idDocumentManagerRegisterEntryBook = JSON.stringify(registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntryBook[0]);
            }

            if (registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntryEvidence && registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntryEvidence.length > 0) {
                model.idDocumentManagerRegisterEntryEvidence = JSON.stringify(registerOffice.annexaFormly.model.modal_body.idDocumentManagerRegisterEntryEvidence[0]);
            }

            RestService.insert('./api/reg/register_entry_office', model).then(function () {
                $rootScope.$broadcast('annexaAdminBaseReloadTable');
                if(GlobalDataFactory.registerEntryOffices){
                	GlobalDataFactory.registerEntryOffices.length = 0;
                }else{
                	GlobalDataFactory.registerEntryOffices = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices')){
                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices')
                }
                GlobalDataFactory.loadGlobalData();
                modal.close();
            }).catch(function (error) {
                modal.alerts.push({msg: $filter('translate')('global.errors.unknownNew')
                });
            })
        }

        var profilesRender = function(data, type, full, meta) {
            var content = '';

            _.forEach(data, function (item) {
                content += content != '' ? ', ' + item.profile[Language.getActiveColumn()] : item.profile[Language.getActiveColumn()];
            });

            return content;
        }

        $scope.additionalColumns = [
            { id: 'acronym', title: $filter('translate')('global.literals.acronym'), sortable: false },
            { id: 'profiles', title:$filter('translate')('global.literals.profiles'), render: profilesRender, sortable: false }
        ];

        $scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name' }
        ];

        // Para los documentos, modificacion y edicion
        $scope.replaceDocumentModal = function(template, callerModal, typeFile) {
            var replaceDocument = function() {
                if(this.annexaFormly.form.$valid) {
                    $rootScope.loading(true)
                    var self = this;
                    var data = this.annexaFormly.model.modal_body;

                    RestService.update('./api/reg/register_entry_office/' + template.id + '/' +typeFile+ '/file', JSON.stringify(data.file[0]))
                        .then(function(data) {
                        	$rootScope.$broadcast('annexaAdminBaseReloadTable');
                            if(GlobalDataFactory.registerEntryOffices){
                            	GlobalDataFactory.registerEntryOffices.length = 0;
                            }else{
                            	GlobalDataFactory.registerEntryOffices = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices')
                            }
                            GlobalDataFactory.loadGlobalData();
                            $rootScope.loading(false);
                            self.close();
                        }).catch(function(error) {
                            $rootScope.loading(false);
                            console.log(error);
                        })
                }
            }

            var modal = globalModals.uploadFileBase64;
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            AnnexaFormlyFactory.showModal('modalUploadFile', modal, replaceDocument, false);
        }

        $scope.editOnline = function (idDocumentManager) {
            CommonService.getEditOnlineUrl(idDocumentManager).then(function(eou) {
                if(eou != null && eou != undefined && eou != ''){
                    window.location.href=eou;
                }
            }).catch(function(){});
        }

        $scope.download = function(idDocumentManager){
        	$rootScope.loading(true);
            $http.get("./api/repodocs/downloadAlfDoc?id="+idDocumentManager, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    var retDownload = {
                        file: undefined,
                        fileName: undefined,
                        linkElement: undefined,
                        clickEvent: undefined
                    }
                    headers = headers();
                    var contentType = headers['content-type'];
                    retDownload.file = new Blob([data], {type: contentType});
                    retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
                    try {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                        } else {
                            retDownload.linkElement = document.createElement('a');
                            var url = window.URL.createObjectURL(retDownload.file);
                            retDownload.linkElement.setAttribute('href', url);
                            retDownload.linkElement.setAttribute("download", retDownload.fileName);
                            retDownload.clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                        }
                        $rootScope.loading(false);
                    } catch (e) {
                        $rootScope.loading(false);
                    }
                }).error(function (error) {
                    $rootScope.loading(false);
            });
        }
        // Cuando guardamos un dato en el formulario o modificamos.
        $scope.updateRegisterOffice = function (val, prop) {

            var model = angular.copy($scope.modelRegOffice.data);

            if(prop == 'language1' || prop == 'language2' || prop == 'language3'){
                model = val;
            }
            else if (prop == 'profiles') {
                model[prop] = [];
                _.forEach(val, function(item) {
                    model[prop].push( { profile: { id: item.id } });
                });
            }
            else if(prop == 'registerEntryInputPattern'){
                model[prop] = val;
            }
            else if(prop == 'registerEntryOutputPattern'){
                model[prop] = val;
            }
            else if(prop == 'registerEntryInputDigits'){
                model[prop] = val;
            }
            else if(prop == 'registerEntryOutputDigits'){
                model[prop] = val;
            }

            else if(prop == 'idDocumentManagerRegisterEntrySupportingDocument'){
                model[prop] = val;
            }
            else if(prop == 'idDocumentManagerRegisterEntryBook'){
                model[prop] = val;
            }
            else if(prop == 'idDocumentManagerRegisterEntryEvidence'){
                model[prop] = val;
            }


            else if(prop == 'registerEntryOutputNotificationChannel'){
                model[prop] = { id: val.id };
            }
            else if(prop == 'registerEntryInputChannel'){
                model[prop] = { id: val.id };
            }
            else if(prop == 'registerEntryEditDays'){
                model[prop] = val;
            }

            else if(prop == 'registerEntryInputInitDossierDiligenceTypeMultiple'){

                model[prop] = val;
            }
            else if(prop == 'registerEntryPending'){

                model[prop] = val;
            }
            else {
                model[prop] = val;
            }

            RestService.update('./api/reg/register_entry_office/' + model.id, model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if(GlobalDataFactory.registerEntryOffices){
                    	GlobalDataFactory.registerEntryOffices.length = 0;
                    }else{
                    	GlobalDataFactory.registerEntryOffices = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=RegisterEntryOffice&origin=registerEntryOffices')
                    }
                    GlobalDataFactory.loadGlobalData();
                })
                .catch(function (error) {
                });
        };

        // Lista y traduce los checkbox
        $scope.printDossier = function (model) {
            if(model) {
                return $filter('translate')('global.literals.registerEntryDossierMultipleSI');
            } else {
                return $filter('translate')('global.literals.registerEntryDossierMultipleNO');
            }
        };

        $scope.printRegistroPendiente = function (model) {
            if(model) {
                return $filter('translate')('global.literals.registerEntryPendingSI');
            } else {
                return $filter('translate')('global.literals.registerEntryPendingNO');
            }
        };

        // Visualizacion del formulario
        $scope.viewElement = function(id) {
            RestService.findOne('RegisterEntryOffice', id)
                .then(function(data) {
                    var modal = angular.copy(CommonAdminModals.editRegisterEntryOffice);
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    modal.data.profiles = $linq(modal.data.profiles).select(function(x) {
                        return {
                            id: x.profile.id,
                            language1: x.profile.language1,
                            language2: x.profile.language2,
                            language3: x.profile.language3,
                            acronym: x.profile.acronym,
                            createdDate: x.profile.createdDate,
                            deleted: x.profile.deleted,
                            expiryDate: x.profile.expiryDate,
                            profileCode: x.profile.profileCode,
                            removedDate: x.profile.removedDate,
                            revision: x.profile.revision
                        }
                    }).toArray();
                    modal.languageColumn = Language.getActiveColumn();

                    modal.registerEntryOutputNotificationChannel = GlobalDataFactory.registerEntryChannelsOutput;
                    modal.registerEntryInputChannel = GlobalDataFactory.registerEntryChannelsInput;
                    modal.profiles = GlobalDataFactory.allProfiles;

                    // Imprime lo que tengamos registrado en el formulario.
                    modal.printProfiles = function (model) {
                        var profs = '';
                        angular.forEach(model, function (val, key) {
                            if(profs != '') {
                                profs += ', ';
                            }

                            profs += val[Language.getActiveColumn()];
                        });

                        return profs;
                    }

                    modal.supportingDocument = "supportingDocument";
                    modal.entryBook = "entryBook";
                    modal.entryEvidence = "entryEvidence";
                    modal.replaceDocumentModal = $scope.replaceDocumentModal;
                    modal.editOnline = $scope.editOnline;
                    modal.download = $scope.download;

                    modal.registerEntryInputInitDossierDiligenceTypeMultiple = $scope.printDossier;
                    modal.registerEntryPending = $scope.printRegistroPendiente;

                    modal.updateRegisterOffice = $scope.updateRegisterOffice;
                    $scope.modelRegOffice = modal;
                    AnnexaModalFactory.showModal('modalRegisterEntryOffice', modal);
                });
        }

    }]);