angular
    .module('annexaApp')
    .controller('AddressTypeController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin',
    		function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin) {
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.addressTypeNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewAddressType', modal, $scope.saveAddressType, false);
        }

        $scope.saveAddressType = function (addressType) {
            var modal = this;

            var model = {
                language1: addressType.annexaFormly.model.modal_body.language1 ? addressType.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                language2: addressType.annexaFormly.model.modal_body.language2 ? addressType.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: addressType.annexaFormly.model.modal_body.language3 ? addressType.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                code: addressType.annexaFormly.model.modal_body.code,
                postalGroup: addressType.annexaFormly.model.modal_body.postalGroup,
                cadastralReferenceGroup: addressType.annexaFormly.model.modal_body.cadastralReferenceGroup,
                coordinatesGroup: addressType.annexaFormly.model.modal_body.coordinatesGroup,
                validateNumbers: ((addressType.annexaFormly.model.modal_body.validateNumbers_true)?true:false),
                permissions:[]
            }
            var permissionsField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'permissions'");
            if(permissionsField && permissionsField.fieldGroup && permissionsField.fieldGroup.length > 0 && permissionsField.fieldGroup[0].data && permissionsField.fieldGroup[0].data.permissions){
	            model.permissions = permissionsField.fieldGroup[0].data.permissions;
            }

            RestService.insert('./api/territory/address_type', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    if(GlobalDataFactory.addressTypes){
                    	GlobalDataFactory.addressTypes.length = 0;
                    }else{
                    	GlobalDataFactory.addressTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('AddressType')){
                    	CacheFactory.get('globalDataCache').remove('AddressType');
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.close();
                }).catch(function () {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('AddressType', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = CommonAdminModals.addressTypeEdit;
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.printValidateNumbers = function(modelValue){
                    	if(modelValue == true || (modelValue && modelValue.id == true) ) {
                            return $filter('translate')('global.commonAdmin.modal.addressType.validateNumbers');
                        } else {
                            return $filter('translate')('global.commonAdmin.modal.addressType.noValidateNumbers');
                        }
                    }
                    
                    modal.printGroups = function(modelValue){
                    	if(modelValue && !modelValue.id && _.contains(['REQUIRED', 'OPTIONAL', 'NOT_REQUIRED'], modelValue)) {
                    		return $filter('translate')('global.literals.'+((modelValue == 'NOT_REQUIRED')?'DISABLED':modelValue));
                    	}else if(modelValue && modelValue.id && _.contains(['REQUIRED', 'OPTIONAL', 'NOT_REQUIRED'], modelValue.id)){
                    		return $filter('translate')('global.literals.'+((modelValue.id == 'NOT_REQUIRED')?'DISABLED':modelValue.id));
                    	}else{
                    		return '';                    		
                    	}
                    }
                    modal.showAddressTypeList = angular.copy(apiAdmin.showAddressTypeList); 
                    modal.descriptionColumn = "description";
                    modal.updateAddressType = $scope.updateAddressType;

                    AnnexaModalFactory.showModal('modalTaskOriginEdit', modal);
                });
        }

        $scope.updateAddressType = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);

            if ( _.contains(['code', 'validateNumbers'], prop)) {
                model[prop] = val;
            } else if( _.contains(['postalGroup', 'cadastralReferenceGroup', 'coordinatesGroup'], prop)){
            	model[prop] = ((val && val.id)?val.id:undefined);
            } else {
                model = val;
            }
            
            RestService.update('./api/territory/address_type/' + model.id, model)
                .then(function (data) {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if( _.contains(['postalGroup', 'cadastralReferenceGroup', 'coordinatesGroup'], prop)){
                    	self.data[prop] = ((val && val.id)?val.id:undefined);
                    }
                    if(GlobalDataFactory.addressTypes){
                    	GlobalDataFactory.addressTypes.length = 0;
                    }else{
                    	GlobalDataFactory.addressTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('AddressType')){
                    	CacheFactory.get('globalDataCache').remove('AddressType');
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function () {
                    //Empty
            });
        }
        
    }])