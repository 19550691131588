angular
    .module('annexaApp')
        .controller('QueryConnectorController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'RestService', 'Language', 'HelperService', 'ErrorFactory','AdminFactory', 'DialogsFactory', 'GlobalDataFactory', 'QuerysModals', 'QueryFactory',
            function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, RestService, Language, HelperService, ErrorFactory, AdminFactory, DialogsFactory, GlobalDataFactory, QuerysModals, QueryFactory) {
                $scope.showLoadingdiv = false;
                $scope.languageColumn = Language.getActiveColumn();
                $scope.tfilter = new TableFilter();
                $scope.tfilter.addElement('name', 'text', 1, 'global.literals.name');

                $scope.dtDefQueryConnector = new TableData('queries', 'QueryConnector', './api/query_connector/byfilter').setSort([[1, 'asc']]).setSortName('name');

                var queryConnectorActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see', 'openQueryConnector([data])', 'fa-eye'),
                    [new ActionButton('global.literals.remove', 'deleteQueryConnector([data])')]);

                $scope.tableQueryConnector = {
                    dtColumns: [
                        DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                        DTColumnBuilder.newColumn('name').withTitle($filter('translate')('global.literals.name')),
                        DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(queryConnectorActionsColumn.getTitle()).renderWith(queryConnectorActionsColumn.getRender).notSortable()
                    ],
                    filterCall: $scope.tfilter.getFilterCall(),
                    filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
                    reLoadInternalData: function (resetPaging) {
                        this.filterCall = $scope.tfilter.getFilterCall();
                        this.filterCallAux = $scope.tfilter.getFilterCallAux();
                        this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        this.filterCallAux.langColumn = $scope.languageColumn;
                        this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                    }
                };

                HeaderService.onChangeState($scope, function (message) {
                    if(message.state.name == 'annexa.admin.query_connector') {
                        $rootScope.subHeadButtons = [
                            new HeadButtonSearch("#tableFilter"),
                            new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#admin-query_connector','fa fa-plus','global.commonAdmin.modal.queryConnector.titleNew',undefined,'openNewQueryConnector')
                        ];
                    }
                });

                HeaderService.changeState($state.current);

                $scope.doSearchFilter = function() {
                    $scope.tableQueryConnector.reLoadInternalData(true);
                };

                $scope.deleteQueryConnector = function(id) {
                    if(id) {
                        RestService.delete('./api/query_connector/' + id)
                            .then(function(data) {
                                $scope.doSearchFilter();
                            }).catch(function (error) {
                            	console.log(error);
                            });
                    }
                };

                $scope.openNewQueryConnector = function () {
                    var modal = angular.copy(CommonAdminModals.queryConnectorNew);
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = { readOnly: false};
                    modal.extra = $scope.tableQueryConnector;
                    
                    AnnexaFormlyFactory.showModal('modalQueryConnector', modal, $scope.saveNewQueryConnector, false, undefined);
                };

                $scope.openQueryConnector = function (id) {
                    if(id) {
                        RestService.findOne('QueryConnector', id)
                            .then(function(data) {
                            	//TODO: crear modal edició
                                var modal = angular.copy(CommonAdminModals.queryConnectorEdit);
                                modal.data = JSOG.decode(data.data);
                                modal.languageColumn = Language.getActiveColumn();
                                modal.extra = $scope.tableQueryConnector;

                                modal.updateQueryConnector = $scope.updateQueryConnector;
                                
                                AnnexaModalFactory.showModal('modalQueryConnectorEdit', modal);
                            });
                    }
                };
                
                $scope.updateQueryConnector = function(val, prop) {
                    var self = this;
                    var queryConnector = angular.copy(this.data);

                    queryConnector[prop] = val;
                    
                    RestService.update('./api/query_connector/' + queryConnector.id, queryConnector)
                        .then(function(data) {
                            self.extra.reLoadInternalData(true);
                        }).catch(function (error) {
                        console.log(error);
                    });

                };

                $scope.saveNewQueryConnector = function(){
                    if (this.annexaFormly.form.$valid) {
                        var self = this;
                        var model = this.annexaFormly.model.modal_body;

                        var queryConnector = {
                            name: model.name,
                            driver: model.driver,
                            connectionUrl: model.connectionUrl
                        };

                        RestService.insert('./api/query_connector', queryConnector)
                            .then(function(data) {
                                self.extra.reLoadInternalData(true);
                                self.close();
                            }).catch(function(error) {
                                console.log(error);
                            });
                    }
                };
            }]);