/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('ProcedureFamiliesController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', '$filter', 'RestService', 'Language', 'CommonAdminService', 'AnnexaModalFactory', 'CommonService', 'AdminFactory', 'dialogs', 'DialogsFactory', '$q', 'GlobalDataFactory', 'CacheFactory', function($scope, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, $filter, RestService, Language, CommonAdminService, AnnexaModalFactory, CommonService, AdminFactory, dialogs, DialogsFactory, $q, GlobalDataFactory, CacheFactory) {
        $scope.showLoadingdiv = false;

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.procedure_families') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton',undefined,'#admin-procedureFamilies','fa fa-plus','global.commonAdmin.modal.familyProcedure.titleNew',undefined,'openNewFamilyProcedure')
                ];
            }
        })

        HeaderService.changeState($state.current)

        //region New

        $scope.langColumn = Language.getActiveColumn();

        $scope.families = CommonService.getNestableData(angular.copy(GlobalDataFactory.familyProcedures), 'familyProcedure', 'orderView');

        $scope.toggle = function (scope) {
            scope.toggle();
        };

        $scope.treeOptions = {
            beforeDrop : function (e) {
            	var deferred = $q.defer();
            	DialogsFactory.confirm('global.commonAdmin.literals.moveFamilyProcedure', 'global.commonAdmin.literals.confirmMoveFamilyProcedure').then(function (dataAux) {
	                var sourceValue = e.source.nodeScope.$modelValue.familyProcedure;
	                var sourceParentValue = null;
	                if( e.source.nodeScope.$modelValue.familyProcedure.parent != null &&  e.source.nodeScope.$modelValue.familyProcedure.parent != undefined) {
	                    sourceParentValue = e.source.nodeScope.$modelValue.familyProcedure.parent.id;
	                }
	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.familyProcedure: null;
	                if(destValue != null && destValue != undefined) {
                    	sourceValue.parent = destValue;
	                }else {
	                    sourceValue.parent = null;
	                }
	                if(e.dest != null){
	                	sourceValue.orderView = e.dest.index+1;
	                }
	                $scope.saveFamilyProcedure(sourceValue, deferred);
            	}).catch(function (data) {
                	deferred.reject(false);
	            });  
            	
            	return deferred.promise;     
            }
        };

        $scope.newFamilyProcedure = function (node) {
            var modal = CommonAdminModals.familyProcedure;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.fields[0].fieldGroup[1].defaultValue = node == undefined ? null : node;
            AnnexaFormlyFactory.showModal("modalFamilyProcedure", modal, $scope.saveFamilyProcedure, false);
        };

        $scope.openEditFamilyProcedure = function (id, readonly) {
            RestService.findOne('FamilyProcedure', id)
                .then(function(data) {
                    var modal = CommonAdminModals.familyProcedureEdit;
                    modal.data = JSOG.decode(data.data);
                    modal.familyAux = {language1:modal.data.language1, language2:modal.data.language2, language3:modal.data.language3};
                    modal.activeColumn = Language.getActiveColumn();
                    modal.saveFamilyProcedureEdit = $scope.saveFamilyProcedureEdit;
                    modal.cancelFamilyProcedureEdit = $scope.cancelFamilyProcedureEdit;
                    AnnexaModalFactory.showModal('modalFamilyProcedureEdit', modal);
                });
        }

        $scope.openNewFamilyProcedure = function (id, readonly) {
            var modal = CommonAdminModals.familyProcedure;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: readonly};
            AnnexaFormlyFactory.showModal("modalFamilyProcedure", modal, $scope.saveFamilyProcedure, false);
        }

        $scope.saveFamilyProcedureEdit = function(modify) {
            var modal = this;
            var languages = $rootScope.app.languagedef;
            var valid = true;
            var cont = 1;
            var column = "language";
            if(languages){
                angular.forEach(languages, function (value2, key2) {
                    if (modal.data && !modal.data[column + cont]) {
                        valid = false;
                    }
                    cont++;
                });
            }
            if(valid) {
                CommonAdminService.insertOrUpdateFamilyProcedure(modal.data).then(function (data) {
                    modal.familyAux = {
                        language1: modal.data.language1,
                        language2: modal.data.language2,
                        language3: modal.data.language3
                    };

                    var indexModifiedFamily = $linq(GlobalDataFactory.familyProcedures).indexOf("x => x.id == " + data.id);

                    if(indexModifiedFamily != -1) {
                    	GlobalDataFactory.familyProcedures[indexModifiedFamily].language1 = modal.data.language1;
                    	GlobalDataFactory.familyProcedures[indexModifiedFamily].language2 = modal.data.language2;
                    	GlobalDataFactory.familyProcedures[indexModifiedFamily].language3 = modal.data.language3;

                        $scope.families = CommonService.getNestableData(GlobalDataFactory.familyProcedures, 'familyProcedure', 'orderView');
                    }
                    if(GlobalDataFactory.familyProcedures){
                    	GlobalDataFactory.familyProcedures.length = 0;
                    }else{
                    	GlobalDataFactory.familyProcedures = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=FamilyProcedure')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=FamilyProcedure')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
                    console.log("ERROR");
                });
            }else{
                return $filter('translate')('global.validation.required');
            }

        };

        $scope.cancelFamilyProcedureEdit = function() {
            var modal = this;
            if(modal && modal.familyAux){
                modal.data.language1 = modal.familyAux.language1;
                modal.data.language2 = modal.familyAux.language2;
                modal.data.language3 = modal.familyAux.language3;
            }
        };

        $scope.saveFamilyProcedure = function(fp, deferred) {
            var dataModel = fp;
            var isNew = false;
            var modal = undefined;

            if(fp.hasOwnProperty('annexaFormly')) {
                modal = this;
                dataModel = angular.copy(this.annexaFormly.model.modal_body);
                isNew = true;
            }


            CommonAdminService.insertOrUpdateFamilyProcedure(dataModel).then(function(data) {
                if(isNew) {
                    modal.close();
                    GlobalDataFactory.familyProcedures.push(JSOG.decode(data));

                    $scope.families = CommonService.getNestableData(GlobalDataFactory.familyProcedures, 'familyProcedure', 'orderView');
                }
                if(GlobalDataFactory.familyProcedures){
                	GlobalDataFactory.familyProcedures.length = 0;
                }else{
                	GlobalDataFactory.familyProcedures = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=FamilyProcedure')){
                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=FamilyProcedure')
                }
                GlobalDataFactory.loadGlobalData();                
                if (deferred) {
                	deferred.resolve(true);
                }
            }).catch(function(error) {
                console.log("ERROR");
                if (deferred) {
            		deferred.resolve(false);
            	}
            });

        };

        $scope.deleteFamilyProcedure = function(id) {
            if(id) {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.commonAdmin.literals.deleteFamilyProcedure'),
                    $filter('translate')('global.commonAdmin.literals.confirmDeleteFamilyProcedureMessage'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );

                dlg.result.then(function (btn) {
                    CommonAdminService.deleteFamilyProcedure(id).then(function (data) {
                    	GlobalDataFactory.familyProcedures = JSOG.decode(data);
                        $scope.families = CommonService.getNestableData(GlobalDataFactory.familyProcedures, 'familyProcedure', 'orderView');
                        if(GlobalDataFactory.familyProcedures){
                        	GlobalDataFactory.familyProcedures.length = 0;
                        }else{
                        	GlobalDataFactory.familyProcedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=FamilyProcedure')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=FamilyProcedure')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        var err = dialogs.error(
                            $filter('translate')('DIALOGS_ERROR_MSG'),
                            $filter('translate')(error.data.message),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                    });
                }, function (btn) {
                });


            }
        };

        //endregion
    }]);