/**
 * Created by osirvent on 20/09/2016.
 */
angular
    .module('annexaApp')
    .factory('AdminFactory',['$http', '$q', 'Language', '$rootScope', 'CommonService', 'apiAdmin', '$translate', '$filter', 'RestService', 'GlobalDataFactory', 'HelperService', function ($http, $q, Language, $rootScope, CommonService, apiAdmin, $translate, $filter, RestService, GlobalDataFactory, HelperService) {
        var factory = {};

        factory.procedures = [];
        factory.templateTypes = [];
        factory.sections = [];
        factory.sectionTypes = [];
        factory.councillors = [];
        factory.users = [];
        factory.roles = [];
        factory.profiles = [];
        factory.signActionTypes = [];
        factory.signatoryTypes = [];
        factory.actionPermissions = [];
        factory.roleEdit = {};
        factory.procedure = undefined;
        factory.customFields = [];
        factory.subprocedures = [];
        factory.configurationGroups = [];
        factory.tramitationPhases = [];
        factory.tramitationTypes = [];
        factory.transactionTypes = [];
        factory.transactionTypesOrdinary = [];
        factory.positions = [];
        factory.politicalParties = [];
        factory.entityEvents = [];
        factory.globalPresetSignCircuits = [];
        factory.aspectEdit = {}
        factory.queryParameters = [];
        factory.queryTablesInformation = [];
        factory.queryConnectors = [];
        factory.accountInstances = [];
        factory.taskTypes = [];
        
        factory.newPosition = function (position) {
            var deferred = $q.defer();

            $http({
                url: './api/admin/position',
                method: 'POST',
                data: JSOG.encode(position)
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.checkDelete = function(position) {
            var deferred = $q.defer();

            $http({
                url: './api/admin/position/check_delete',
                method: 'GET',
                params: { id: position }
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deletePosition = function(position) {
            var deferred = $q.defer();

            $http({
                url: './api/admin/position',
                method: 'DELETE',
                params: { id: position }
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.getRoles = function () {
            return $http({
                method: 'GET',
                url: './api/admin/role'
            }).then(function (data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.actionPermissions = decodedData;
            }).catch(function (err) {
            })
        }

        factory.newRole = function (role) {
            var deferrend = $q.defer();

            $http({
                url: './api/admin/role',
                method: 'POST',
                data: JSOG.encode(role)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error)
            });

            return deferrend.promise;
        }

        factory.updateRole = function (model) {
            var deferrend = $q.defer();

            $http({
                method: 'PUT',
                url: './api/admin/role',
                data: JSOG.encode(model)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.getPositions = function () {
            return RestService.findAll('Position')
                .then(function(data) {
                    factory.positions = JSOG.decode(data.data);
                }).catch(function (error) {
                });
        }

        factory.getPoliticalParties = function() {
            return RestService.findAll('PoliticalParty')
                .then(function(data) {
                    factory.politicalParties = JSOG.decode(data.data);
                }).catch(function(error) {
                    factory.politicalParties = [];
                })
        }

        factory.getUsers = function() {
            return $http({
                method: 'GET',
                url: './api/users'
            }).then(function (data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.sections = decodedData.sections;
                factory.roles = decodedData.roles;
                factory.profiles = decodedData.profiles;
                factory.positions = decodedData.positions;
            }).catch(function (error) {
            });
        };

        factory.resetPassword = function(id, translations) {
            var deferred = $q.defer();

            $http({
                url: './api/users/reset_password/' + id,
                method: 'PUT',
                data: translations
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        factory.newUser = function (user) {
            var deferrend = $q.defer();

            $http({
                url: './api/users',
                method: 'POST',
                data: JSOG.encode(user)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.updateUser = function (model, certificate, photo) {
            var deferrend = $q.defer();
            var send = {user: model};
            if(certificate){
                send.certificate = certificate;
            }
            if(photo){
                send.photo = photo;
            }
            $http({
                method: 'PUT',
                url: './api/users',
                data: JSOG.encode(send)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.updatePhotoUser = function (model,photo) {
            var deferrend = $q.defer();
            if(model.id && photo) {
                $http({
                    method: 'PUT',
                    url: './api/users/photo/' + model.id,
                    data: JSOG.encode(photo)
                }).then(function (data) {
                    deferrend.resolve(data);
                }).catch(function (error) {
                    deferrend.reject(error);
                });
            }else{
                deferrend.reject(error);
            }
            return deferrend.promise;
        }

        factory.deleteTemplateType = function (template) {
            var deferrend = $q.defer();

            $http({
                url: './api/doc/template_type/' + template.id + '/return_all',
                method: 'DELETE'
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.templateTypes = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.newTemplateType = function (template) {
            var deferrend = $q.defer();

            $http({
                url: './api/doc/template_type/return_all',
                method: 'POST',
                data: JSOG.encode(template)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.templateTypes = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.deleteSection = function (section) {
            var deferrend = $q.defer();

            $http({
                url: './api/sections',
                method: 'DELETE',
                params: { sectionId: section.id }
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.sections = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.newSection = function(section) {
            var deferrend = $q.defer();

            $http({
                url: './api/sections',
                method: 'POST',
                data: JSOG.encode(section)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.sections = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.getSections = function () {
            return $http({
                method: 'GET',
                url: './api/sections'
            }).then(function(data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.sections = decodedData.sections;
                factory.sectionTypes = decodedData.sectionTypes;
                factory.councillors = decodedData.councillors;
                factory.users = [];
                angular.forEach(decodedData.users, function (value, key) {
                    value.completeName = ((value.name || '') + ' ' + (value.surename1 || '') + ' ' + (value.surename2 || '')).trim();
                    factory.users.push(value);
                });
            }).catch(function (error) {
            });
        };

        factory.deleteCouncillor = function (councillorId) {
            var deferrend = $q.defer();

            $http({
                url: './api/councillors',
                method: 'DELETE',
                params: { councillorId: councillorId }
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.newCouncillor = function (councillor) {
            var deferrend = $q.defer();

            $http({
                url: './api/councillors',
                method: 'POST',
                data: JSOG.encode(councillor)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.getTemplateTypes = function() {
            return $http({
                url: './api/doc/template_type',
                method: 'GET'
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.templateTypes = decodedData;
            }).catch(function (error) {
            });
        };

        factory.newDocTemplate = function () {
            var promises = [];

            promises.push(RestService.findAll('TemplateType'));
            
            return $q.all(promises)
                .then(function(data) {
                    factory.templateTypes = JSOG.decode(data[0].data);
                }).catch(function(error) {
                    factory.templateTypes = [];
                });
        };

        factory.updateDocTemplate = function (model) {
            var deferred = $q.defer();

            RestService.update('./api/doc/template/' + model.id, model)
                .then(function(data) {
                    deferred.resolve(data);
                }).catch(function(error) {
                    deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateTramTemplate = function (model) {
            var deferrend = $q.defer();

            $http({
                method: 'PUT',
                url: './api/tram/template',
                data: JSOG.encode(model)
            }).then(function(data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.addTramTemplate = function (model) {
            var deferrend = $q.defer();

            var formData = new FormData();
            angular.forEach(model, function (value, key) {
                formData.append(key, value);
            });

            $http({
                method: 'POST',
                url: './api/tram/template',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
                deferrend.resolve(data);
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }

        factory.updateTramTemplate = function (model) {
            var deferrend = $q.defer();

            $http({
                method: 'PUT',
                url: './api/tram/template',
                data: JSOG.encode(model)
            }).then(function(data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.addTramTemplate = function (model) {
            var deferrend = $q.defer();

            var formData = new FormData();
            angular.forEach(model, function (value, key) {
                formData.append(key, value);
            });

            $http({
                method: 'POST',
                url: './api/tram/template',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
                deferrend.resolve(data);
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }

        factory.getTramFieldsMetadataXML = function(json) {
            var deferrend = $q.defer();

            if(!json) {
                json = false;
            }

            $http({
                method: 'GET',
                url: './api/tram/fromtemplate',
                params: { json: json }
            }).success(function(data, status, headers) {
                headers = headers();

                var contentType = headers['content-type'];

                var linkElement = document.createElement('a');
                try {
                    if(json) {
                        var blob = new Blob([JSON.stringify(data)], {type: contentType});
                        var url = window.URL.createObjectURL(blob);
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute('download', 'annexa.fields.json');
                    } else {
                        var blob = new Blob([data], {type: contentType});
                        var url = window.URL.createObjectURL(blob);
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute('download', 'annexa.fields.xml');
                    }

                    deferrend.resolve(linkElement);
                } catch(ex) {
                    deferrend.reject(ex);
                }
            }).error(function(error) {
                deferrend.reject(error);
            })

            return deferrend.promise;
        }

        factory.newPresetSignCircuit = function(){
            return $http({
                url: './api/presetsigncircuits/getPresetSignCircuitData',
                method: 'GET'
            }).then(function(data) {
                var presetData = JSOG.decode(data.data);
                factory.signActionTypes = presetData.signActionTypes;
                factory.signatoryTypes = presetData.signatoryTypes;
            }).catch(function (error) {
            });
        };

        factory.getConfigurationGroups = function () {
            return RestService.findAll('ConfigurationGroup')
                .then(function(data) {
                    factory.configurationGroups = JSOG.decode(data.data);
                }).catch(function (error) {
                });
        };

        factory.getCustomFields = function () {
        	var deferred = $q.defer();
        	factory.customFields = GlobalDataFactory.customFields;
            deferred.resolve('OK');
            return deferred.promise;
        };
        
        factory.getQueryParameters = function () {
            return RestService.findAll('QueryParameter')
                .then(function (data) {
                    factory.queryParameters = JSOG.decode(data.data);
                }).catch(function (error) {
                });
        };

        factory.updateConfiguration = function (key, value, show_folders_types) {
            var deferred = $q.defer();

            $http({
                url: './api/configuration',
                method: 'PUT',
                data: { key_name: key, value: value, show_folders_types: show_folders_types}
            }).then(function(data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateConfigurations = function(configurations) {
            var deferred = $q.defer();

            $http({
                url: './api/admin/configuration',
                method: 'POST',
                data: configurations
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        factory.saveBPM = function (procedure) {
            var deferrend = $q.defer();

            $http({
                url: './api/admin/bpm/save',
                method: 'POST',
                data: JSOG.encode(procedure)
            }).then(function(data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.getFamilyProceduresReload = function () {
            return RestService.findAll('FamilyProcedure')
                .then(function(data) {
                	GlobalDataFactory.familyProcedures = JSOG.decode(data.data);
                	GlobalDataFactory.familiesProcedureTree = CommonService.getTreeData(angular.copy(GlobalDataFactory.familyProcedures), Language.getActiveColumn());
                }).catch(function(error) {
                });
        }

        factory.newClassification = function (classification) {
            var deferrend = $q.defer();

            $http({
                url: './api/archiveClassifications/archiveClassification',
                method: 'POST',
                data: JSOG.encode(classification)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };
        factory.deleteClassification = function (classification) {
            var deferrend = $q.defer();

            $http({
                url: './api/archiveClassifications/archiveClassification',
                method: 'DELETE',
                params: { classificationId: classification.id }
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.insertOrUpdateRegisterClassificationEdit = function (classification) {
            var deferrend = $q.defer();

            $http({
                url: './api/registerClassifications/registerClassification',
                method: 'POST',
                data: JSOG.encode(classification)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.insertOrUpdateTaskTemplate = function (taskTemplate) {
            var deferrend = $q.defer();

            $http({
                url: './api/taskTemplates/taskTemplate',
                method: 'POST',
                data: JSOG.encode(taskTemplate)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(decodedData);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.updateTaskTemplateCustomField = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'PUT',
                url: './api/taskTemplates/' + customField.taskTemplate.id + '/custom_field',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };

        factory.deleteRegisterClassification = function (classification) {
            var deferrend = $q.defer();

            $http({
                url: './api/registerClassifications/registerClassification',
                method: 'DELETE',
                params: { classificationId: classification }
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.deleteTaskTemplate = function (taskTemplate) {
            var deferrend = $q.defer();

            $http({
                url: './api/taskTemplates/taskTemplate',
                method: 'DELETE',
                params: { taskTemplateId: taskTemplate }
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.setupGuidedProcedures = function (procedure) {
            return $http({
                url: './api/admin/procedure/setup',
                method: 'GET',
                params: { procedure: procedure }
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                var families = decodedData.familyProcedures;
                families = $linq(families).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                factory.profiles = decodedData.profiles;
                factory.procedure = decodedData.procedure;
                factory.subprocedures = decodedData.subprocedures;
                factory.tramitationTypes = decodedData.tramitationTypes;
                factory.transactionTypes = decodedData.transactionTypes;
                if(factory.transactionTypes && factory.transactionTypes.length > 0){
                	factory.transactionTypes = $linq(factory.transactionTypes).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                }
                factory.transactionTypesOrdinary = $linq(decodedData.transactionTypes).where("x=>x.tramitationType.id == "+$rootScope.app.configuration.default_tramitation_type.value).toArray();
                if(factory.transactionTypesOrdinary && factory.transactionTypesOrdinary.length > 0){
                	factory.transactionTypesOrdinary = $linq(factory.transactionTypesOrdinary).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                }
                factory.customFields = [];
                factory.customFields.push({ id:"-1", language1: $filter('translate')('global.literals.new'), language2: $filter('translate')('global.literals.new'), language3: $filter('translate')('global.literals.new')});
                var cf = $linq(decodedData.customFields).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                _.forEach(cf, function (item) {
                    factory.customFields.push(item);
                });
            }).catch(function (error) {
            });
        };

        factory.getTramitationPhases = function(){
            return $http({
                url: './api/tramitationPhases/phases',
                method: 'GET'
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.tramitationPhases = decodedData;
            }).catch(function (error) {
            });
        };

        factory.getProcessForm = function () {
            var data = {
                row: true,
                colClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
                labelClass: 'label-strong'
            };

            var addClassificationFuntion = function(){
                var id = 0;

                if(this.optionAdd) {
                    var classification = {};
                    classification[Language.getActiveColumn()] = this.optionAdd;
                    classification.deleted = false;
                    if(form.model.procedureClassifications){
                        form.model.procedureClassifications.push(classification);
                    }else{
                        form.model.procedureClassifications = [];
                        form.model.procedureClassifications.push(classification);
                    }
                    this.optionAdd = '';
                }
            };

            var removeClassificationFuntion = function(index){
                if(form.model.procedureClassifications && form.model.procedureClassifications.length > index){
                    form.model.procedureClassifications.splice(index, 1);
                }
            };

            var form = new AnnexaFormly();
            form.addFieldGroup('basicinfo','accordionWrapper', new AnnexaFormlyFieldAccordionTemplateOptions('global.literals.basicInfo'),
                [
                    form.createField('language1', 'annexaInputLanguage', '', new AnnexaFormlyFieldLanguageTemplateOptions('language', '', 'global.literals.name', 'horizontal', true, false, true, $rootScope.app.languagedef), data),
                    form.createField('descriptionLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('descriptionLanguage','descriptionLanguage','global.literals.description','horitzontal',true,false,true,$rootScope.app.languagedef),data),
                    form.createField('acronym','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.literals.acronym',false,false,undefined,20),data),
                    form.createField('maxDays','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.literals.days',true,false),data),
                    form.createField('showSubject','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.showSubject','id','description',apiAdmin.showSubjectList,true),data)
                ]
            );

            form.addFieldGroup('classifications','accordionWrapper', new AnnexaFormlyFieldAccordionTemplateOptions('global.literals.classifications'),
                [
                    form.createField('family','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.family','id',Language.getActiveColumn(),GlobalDataFactory.familyProcedures,true),data),
                    form.createField('archiveClassification','annexaSelectTreeRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.classificationBox','id',Language.getActiveColumn(),GlobalDataFactory.archiveClassificationsTree,false),data),
                    form.createField('showClassification','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.showClassification','id','description',apiAdmin.showClassificationList,true),data),
                    form.createField('procedureClassifications','annexaLanguageFieldSet','',new AnnexaFormlyFieldFieldSetTemplateOptions('global.literals.classification',undefined,'','global.commonAdmin.modal.unguidedProcedure.placeholderClassification',addClassificationFuntion,removeClassificationFuntion,'',true, false),data,'model.classifications.showClassification == \'DISABLED\'')
                ]
            );

            form.addFieldGroup('profiles','accordionWrapper', new AnnexaFormlyFieldAccordionTemplateOptions('global.literals.profiles'),
                [
                    form.createField('procedureStartProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialize','id',Language.getActiveColumn(),factory.profiles,true),data),
                    form.createField('procedureViewProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.showProcedure','id',Language.getActiveColumn(),factory.profiles,true),data)
                ]
            );


            form.addFieldGroup('others','accordionWrapper', new AnnexaFormlyFieldAccordionTemplateOptions('global.literals.others'),
                [
                    form.createField('informationLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('informationLanguage','informationLanguage','global.literals.information','horitzontal',false,false,true,$rootScope.app.languagedef),data)
                ]
            );

            var procedureInitials = {
                showClassification: "DISABLED",
                showSubject: "DISABLED",
                classification: {},
                procedureClassifications: [],
                archiveClassification: {}
            };

            form.model = new procedure(procedureInitials);

            return form;
        };
        
        factory.getEntityEvents = function() {
            var deferrend = $q.defer();
            if($rootScope.LoggedUser && $rootScope.LoggedUser.entity){
                factory.entityEvents = $rootScope.LoggedUser.entity;
                if(!factory.entityEvents.holidays){
                    factory.entityEvents.holidays = [];
                }
                deferrend.resolve(factory.entityEvents);
            }else if($rootScope.app.configuration.entity){
                RestService.findOne('Entity', $rootScope.app.configuration.entity.value).then(function(data) {
                    factory.entityEvents = JSOG.decode(data.data);
                    if(!factory.entityEvents.holidays){
                        factory.entityEvents.holidays = [];
                    }
                    deferrend.resolve(factory.entityEvents);
                }).catch(function (error) {
                });
            }
            return deferrend.promise;
        };

        factory.getQueryTablesInformation = function(connectorId){
        	var urlTablesInformation = './api/query/tablesInformation';
        	if (connectorId)
        		urlTablesInformation += '/' + connectorId;
            return $http({
                method: 'GET',
                url: urlTablesInformation
            }).then(function(data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.queryTablesInformation = decodedData;
            }).catch(function (error) {
                factory.queryTablesInformation = [];
            });
        }

        factory.getQueryColumnsInformation = function(table, connectorId){
            var deferrend = $q.defer();
        	var urlColumnsInformation = './api/query/columnsInformation/' + table;
        	if (connectorId)
        		urlColumnsInformation += '/' + connectorId;
            $http({
                method: 'GET',
                url: urlColumnsInformation
            }).then(function(data, status) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(decodedData);
            }).catch(function (error) {
                deferrend.resolve([]);
            });
            return deferrend.promise;
        }
        
        factory.getQueryConnectors = function () {
    		return RestService.findAll('QueryConnector')
            .then(function (data) {
                factory.queryConnectors = JSOG.decode(data.data);
            }).catch(function (error) {
                factory.queryConnectors = [];
            });
        };
        
        factory.getAccountInstances = function() {
            return $http({
                method: 'GET',
                url: './api/admin/accountInstances'
            }).then(function (data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.accountInstances = decodedData;
            }).catch(function (error) {
            });
        };
        
        factory.getTaskTypes = function() {
    		return RestService.findAll('TaskType')
            .then(function (data) {
                factory.taskTypes = JSOG.decode(data.data);
            }).catch(function (error) {
                factory.taskTypes = [];
            });
        };

        return factory;
    }]);
