/**
 * Created by osirvent on 02/03/2017.
 */
angular
    .module('annexaApp')
    .controller('ConfigurationController',['$scope', 'AdminFactory', 'Language', '$rootScope', '$filter', 'HeaderService', '$timeout', '$state', 'dialogs', 'AnnexaFormlyFactory', 'RestService', 'GlobalDataFactory', 'CacheFactory', 'CommonAdminModals', 'AnnexaModalFactory', function ($scope, AdminFactory, Language, $rootScope, $filter, HeaderService, $timeout, $state, dialogs, AnnexaFormlyFactory, RestService, GlobalDataFactory, CacheFactory, CommonAdminModals, AnnexaModalFactory) {
        var data = {
            row: true,
            colClass: 'col-sm-12',
            labelClass: 'label-strong',
            extralabel: true,
            inline: true
        };

        //region Documents per tipus

        $scope.documents_by_type = function() {
            var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'documents_by_type'");
            var conf = $linq(GlobalDataFactory.configuration).singleOrDefault(undefined, "x => x.key === 'documents_by_type'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));

            var configurationModal = {
                title: title.templateOptions.label,
                size: 'modal-lg',
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};

            form.addField(
                'active',
                'annexaRadioCheckboxRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.active',
                    'value',
                    'label',
                    [{ value: 'true', label: $filter('translate')('global.literals.yes') }, { value: 'false', label: $filter('translate')('global.literals.no') }],
                    false,
                    'radio'
                ),
                data
            );

            form.addField(
                'docTypes',
                'annexaDocumentsByTypeConfiguration',
                'col-sm-12',
                new AnnexaFormlyFieldTemplateOptions('text', 'lll', false, false),
                data,
                function($viewValue, $modelValue, scope) {
                    return scope.model.active != 'true';
                }
            );

            form.model = confValue;

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() {
                var showFoldersTypes = [];

                if(this.annexaFormly.model.active == "true" && this.annexaFormly.model.docTypes.length == 0) {
                    this.alerts.push({ msg: 'global.errors.addDocumentType' });
                } else {
                    var saveConf = '';

                    if(this.annexaFormly.model.active == "false") {
                        saveConf = '{"active":"false"}';
                    } else {
                        saveConf = '{"active":"true","docTypes":[';

                        var separator = '';

                        _.forEach(this.annexaFormly.model.docTypes, function(value) {
                            if(value.showFolders) {
                                showFoldersTypes.push(value.type.id);
                            }

                            saveConf += separator;
                            saveConf += '{';
                            saveConf += '"type":{"id":' + value.type.id + ',"language1":"' + value.type.language1 + '","language2":"' + value.type.language2 + '","language3":"' + value.type.language1 + '"},';
                            saveConf += '"showList":' + (value.showList ? 'true' : 'false') + ',';
                            saveConf += '"showFolders":' + (value.showFolders ? 'true' : 'false') + ',';
                            saveConf += '"iconType":{"id":"' + value.iconType.id + '","description":"' + value.iconType.description + '"},';
                            saveConf += '"icon":"' + value.icon + '",';

                            saveConf += '"tableColumns":[';

                            var separator1 = ''
                            _.forEach(value.tableColumns, function (value1) {
                                saveConf += separator1;
                                saveConf += '{';
                                saveConf += '"id":"' + value1.id + '",';
                                saveConf += '"language1":"' + value1.language1 + '",'
                                saveConf += '"language2":"' + value1.language2 + '",'
                                saveConf += '"language3":"' + value1.language3 + '",'
                                saveConf += '"type":"' + value1.type + '"'
                                saveConf += '}'
                                separator1 = ',';
                            })

                            saveConf += '],';

                            saveConf += '"filterColumns":[';

                            var separator1 = ''
                            _.forEach(value.filterColumns, function (value1) {
                                saveConf += separator1;
                                saveConf += '{';
                                saveConf += '"id":"' + value1.id + '",';
                                saveConf += '"language1":"' + value1.language1 + '",'
                                saveConf += '"language2":"' + value1.language2 + '",'
                                saveConf += '"language3":"' + value1.language3 + '",'
                                saveConf += '"type":"' + value1.type + '"'
                                saveConf += '}'
                                separator1 = ',';
                            })

                            saveConf += '],';

                            saveConf += '"folderColumns":[';

                            var separator1 = ''
                            _.forEach(value.folderColumns, function (value1) {
                                saveConf += separator1;
                                saveConf += '{';
                                saveConf += '"id":"' + value1.id + '",';
                                saveConf += '"language1":"' + value1.language1 + '",'
                                saveConf += '"language2":"' + value1.language2 + '",'
                                saveConf += '"language3":"' + value1.language3 + '",'
                                saveConf += '"type":"' + value1.type + '"'
                                saveConf += '}'
                                separator1 = ',';
                            })

                            saveConf += ']';


                            saveConf += '}'

                            separator = ','
                        });

                        saveConf += ']}';
                    }

                    var self = this;

                    AdminFactory.updateConfiguration('documents_by_type', saveConf, showFoldersTypes)
                        .then(function(data) {
                            self.close();
                            var indexConfig = $linq(GlobalDataFactory.configuration).indexOf("x => x.key === 'documents_by_type'");
                            if(indexConfig != -1) {
                            	GlobalDataFactory.configuration = [];
                                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                                	CacheFactory.get('globalDataCache').remove('configuration')
                                }
                                GlobalDataFactory.loadGlobalData();
                            }

                        }).catch(function (error) {
                            self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
                    });
                }
            }

            AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
        }

        //endregion

        
        //region secretaria
        $scope.secretary_properties =  function(){
        	var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'secretary_properties'");
            var conf = $linq(GlobalDataFactory.configuration).singleOrDefault(undefined, "x => x.key === 'secretary_properties'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var profiles = GlobalDataFactory.allProfiles;
            var numbersTypesSecProp = [
            	{id:'global', name:$filter('translate')('global.sec.literals.global')},
            	{id:'anual', name:$filter('translate')('global.sec.literals.anual')}
            ];
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };
            
            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		if(key == 'secretary_profile'){
		            	form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        Language.getActiveColumn(),
		                        profiles,
		                        true
		                    ),
		                    data
		                );
            		}else if(_.contains(['session_number','proposal_number'],key)){
            			form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        'name',
		                        numbersTypesSecProp,
		                        true
		                    ),
		                    data
		                );
            		}else if(key == 'proposal_leading_zeros'){
            			form.addField(
                            key,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                'global.sec.literals.'+key,
                                false
                            ),
                            data
                        );
            		}else if(key == 'proposal_number_pattern'){
            			form.addField(
                            key,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                'text',
                                'global.sec.literals.'+key,
                                false
                            ),
                            data
                        );

            		}else if(key == 'spa_required'){
            			form.addField(
                            key,
                            'annexaRadioCheckboxRow',
                            '',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                            	'global.sec.literals.'+key,
                                'value',
                                'label',
                                [{ value: true, label: $filter('translate')('global.literals.yes') }, { value: false, label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
                        );
            		}
            	});
            }
            form.model = angular.copy(confValue);
            form.model.proposal_leading_zeros =  Number(form.model.proposal_leading_zeros);
            form.model.spa_required = ((form.model.spa_required && form.model.spa_required == "true")?true:false);
            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		if(key == 'proposal_leading_zeros' || key == 'spa_required'){
            			sendValue[key] = modelModal[key]+"";
            		}else{
            			sendValue[key] = modelModal[key];
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('secretary_properties', sendValue, undefined)
                .then(function(data) {
                    self.close();
                    var indexConfig = $linq(GlobalDataFactory.configuration).indexOf("x => x.key === 'secretary_properties'");
                    if(indexConfig != -1) {
                    	GlobalDataFactory.configuration= [];
                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }

                }).catch(function (error) {
                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
        
        $scope.sec_document_types =  function(){
        	var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'sec_document_types'");
            var conf = $linq(GlobalDataFactory.configuration).singleOrDefault(undefined, "x => x.key === 'sec_document_types'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var documentTypes = GlobalDataFactory.allDocumentTypes;
            var documentStatussesValid = angular.copy(GlobalDataFactory.documentStatusesValidConfig);
            if(documentStatussesValid){
            	_.forEach(documentStatussesValid, function(value){
            		value.nameLiteral = $filter('translate')(value.nameLiteral);
            	});
            }
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            var modelToModal = {};
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
            		modelToModal[key] = [];
            		modelToModal[key+'_state'] = [];
            		if(confValue && confValue[key] && confValue[key].id){
            			_.forEach(confValue[key].id, function(id){
            				modelToModal[key].push(parseInt(id));
            			});
            		}
            		if(!_.contains(['SPT', 'STR'], key)){
	            		if(confValue && confValue[key] && confValue[key].valid_states){
	            			_.forEach(confValue[key].valid_states, function(valid_state){
	            				modelToModal[key+'_state'].push(valid_state);
	            			});
	            		}
            		}
            		form.addField(
	            		key,
	                    'annexaMultipleSelectRow',
	                    '',
	                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    	confValue[key].name,
	                        'id',
	                        Language.getActiveColumn(),
	                        documentTypes,
	                        true, 
	                        undefined, 
	                        undefined, 
	                        undefined, 
	                        undefined, 
	                        true
	                    ),
	                    data
	                );
            		if(!_.contains(['SPT', 'STR'], key)){
            			form.addField(
    	            		key+"_state",
    	                    'annexaMultipleSelectRow',
    	                    'col-sm-12',
    	                    new AnnexaFormlyFieldSelectTemplateOptions(
    	                    	confValue[key].name+'_state',
    	                        'id',
    	                        'nameLiteral',
    	                        documentStatussesValid,
    	                        true, 
    	                        undefined, 
    	                        undefined, 
    	                        undefined, 
    	                        undefined, 
    	                        true
    	                    ),
    	                    data
    	                );
            		}
            	});
            }
            form.model = modelToModal;

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(confValue);
            	_.forEach(keysModal, function(key){
            		if(!key.endsWith('_state')){
	            		var docType = sendValue[key];
	            		docType.id = [];
	            		docType.valid_states = [];
	            		_.forEach(modelModal[key], function(id){
	            			docType.id.push(id+"");
	            		});
	            		if(!_.contains(['SPT', 'STR'], key)){
	            			docType.valid_states = modelModal[key+'_state'];
	            		}
	            		
            		}
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('sec_document_types', sendValue, undefined)
                .then(function(data) {
                    self.close();
                    var indexConfig = $linq(GlobalDataFactory.configuration).indexOf("x => x.key === 'sec_document_types'");
                    if(indexConfig != -1) {
                    	GlobalDataFactory.configuration = [];
                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }

                }).catch(function (error) {
                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
            	});
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}
		$scope.sec_organ_member_rols =  function(){
			var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'sec_organ_member_rols'");
            var conf = $linq(GlobalDataFactory.configuration).singleOrDefault(undefined, "x => x.key === 'sec_organ_member_rols'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            var organMembers = angular.copy(GlobalDataFactory.organMemberRols);
            var configurationModal = {
                title: title.templateOptions.label,
                icon: 'fa fa-gear',
                annexaFormly: {},
                alerts: [],
                submitModal: function () {}
            };

            var form = new AnnexaFormly();
            form.model = {};
            var keys = Object.keys(confValue);
            if(confValue && keys.length > 0){
            	_.forEach(keys, function(key){
	            	form.addField(
	            		key,
	                    'annexaSelectRow',
	                    '',
	                    new AnnexaFormlyFieldSelectTemplateOptions(
	                    	'global.sec.literals.'+key,
	                        'id',
	                        Language.getActiveColumn(),
	                        organMembers,
	                        true
	                    ),
	                    data
	                );
            	});
            }
            form.model = angular.copy(confValue);

            configurationModal.annexaFormly = form;

            var saveConfiguration = function() { 
            	var self = this;
            	var modelModal = this.annexaFormly.model;
            	var keysModal = Object.keys(modelModal);
            	var sendValue = angular.copy(modelModal);
            	_.forEach(keysModal, function(key){
            		sendValue[key] = modelModal[key];
            	});
            	sendValue = JSON.stringify(sendValue)
            	AdminFactory.updateConfiguration('sec_organ_member_rols', sendValue, undefined)
                .then(function(data) {
                    self.close();
                    var indexConfig = $linq(GlobalDataFactory.configuration).indexOf("x => x.key === 'sec_organ_member_rols'");
                    if(indexConfig != -1) {
                    	GlobalDataFactory.configuration = [];
                    	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }

                }).catch(function (error) {
                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
            });
			}
	
	        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
    	}

		$scope.user_positions =  function(){
			RestService.findAll('Position').then(function(dataP) {
				var title = $linq($scope.form.fields).singleOrDefault({ templateOptions: { label: '' } }, "x => x.key == 'user_positions'");
	            var conf = $linq(GlobalDataFactory.configuration).singleOrDefault(undefined, "x => x.key === 'user_positions'");
	            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
	            var positions = JSOG.decode(dataP.data);
	            var configurationModal = {
	                title: title.templateOptions.label,
	                icon: 'fa fa-gear',
	                annexaFormly: {},
	                alerts: [],
	                submitModal: function () {}
	            };
	
	            var form = new AnnexaFormly();
	            form.model = {};
	            var keys = Object.keys(confValue);
	            if(confValue && keys.length > 0){
	            	_.forEach(keys, function(key){
		            	form.addField(
		            		key,
		                    'annexaSelectRow',
		                    '',
		                    new AnnexaFormlyFieldSelectTemplateOptions(
		                    	'global.sec.literals.'+key,
		                        'id',
		                        Language.getActiveColumn(),
		                        positions,
		                        true
		                    ),
		                    data
		                );
	            	});
	            }
	            form.model = angular.copy(confValue);
	
	            configurationModal.annexaFormly = form;
	
	            var saveConfiguration = function() { 
	            	var self = this;
	            	var modelModal = this.annexaFormly.model;
	            	var keysModal = Object.keys(modelModal);
	            	var sendValue = angular.copy(modelModal);
	            	_.forEach(keysModal, function(key){
	            		sendValue[key] = modelModal[key];
	            	});
	            	sendValue = JSON.stringify(sendValue)
	            	AdminFactory.updateConfiguration('user_positions', sendValue, undefined)
                    .then(function(data) {
                        self.close();
                        var indexConfig = $linq(GlobalDataFactory.configuration).indexOf("x => x.key === 'user_positions'");
                        if(indexConfig != -1) {
                        	GlobalDataFactory.configuration = [];
                        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                            	CacheFactory.get('globalDataCache').remove('configuration')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }

                    }).catch(function (error) {
                        self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
                });
				}
		
		        AnnexaFormlyFactory.showModal('configurationModal', configurationModal, saveConfiguration, false);
			}).catch(function (error) {
            });
    	}

		//endregion
		
		//region Signatura
		
		$scope.sign_default_config_user = function (){
			
			var conf = $linq(GlobalDataFactory.configuration).singleOrDefault(undefined, "x => x.key === 'sign_default_config_user'");
            var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));
            
            var modal = CommonAdminModals.signDefaultConfigUser;
            modal.confValue = confValue;
            modal.tenant = $scope.LoggedUser.tenant;
            modal.submitLabel = 'global.literals.save';
            modal.submitFunction = function() { 
            	var self = this;
                self.alerts.length = 0;
            	var position = JSON.stringify(self.confValue);
            	AdminFactory.updateConfiguration('sign_default_config_user', position, undefined)
	                .then(function(data) {
	                    self.close();
	                    var indexConfig = $linq(GlobalDataFactory.configuration).indexOf("x => x.key === 'sign_default_config_user'");
	                    if(indexConfig != -1) {
                        	GlobalDataFactory.configuration = [];
                        	if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                            	CacheFactory.get('globalDataCache').remove('configuration')
                            }
                            GlobalDataFactory.loadGlobalData();
	                    }
	
	                }).catch(function (error) {
	                    self.alerts.push({ msg: 'global.errors.errorUpdateConfigValue' });
	                });
			}
            
            AnnexaModalFactory.showModal('modaSignDefaultConfigUser', modal);
        };
		
		//endregion
        
        //region General

        $scope.alerts = [];

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.configuration') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton',undefined,'#admin-general-configuration','fa-floppy-o','global.literals.save',undefined,'saveConfiguration'),
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.saveConfiguration = function() {
            $timeout(function () {
                angular.element('#smConfiguration').trigger('click');
            })
        };

        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };



        var getValue = function(confValue) {
            var value = '';

            if(confValue.hasOwnProperty('value')) {
                value = confValue.value;
            } else if(confValue.hasOwnProperty('id')) {
                value = confValue.id;
            }

            return value;
        }

        $scope.form = new AnnexaFormly();
        $scope.form.model = {};

        var confGroups = $linq(AdminFactory.configurationGroups).orderBy("x => x.viewOrder").toArray();

        _.forEach(confGroups, function(group) {
            $scope.form.addField(
                'GROUP' + group.id,
                'annexaTitle',
                '',
                new AnnexaFormlyFieldTemplateOptions(undefined,group[Language.getActiveColumn()])
            );


            var confs = $linq(GlobalDataFactory.configuration).where("x => x.group.id == " + group.id + " && (x.frontendType != 'NONE' || x.frontendType != 'CUSTOM')").orderBy("x => x.viewOrder").toArray();

            _.forEach(confs, function(conf) {
                var confValue = JSON.parse(conf.value.replace(/\r?\n|\r/g, " "));

                switch (conf.frontendType) {
                    case 'TEXT':
                        $scope.form.addField(
                            conf.key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'text',
                                conf[Language.getActiveColumn()],
                                false
                            ),
                            data
                        );

                        $scope.form.model[conf.key] = getValue(confValue);
                        break;
                    case 'BOOLEAN':
                        $scope.form.addField(
                            conf.key,
                            'annexaRadioCheckboxRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldSelectTemplateOptions(
                                conf[Language.getActiveColumn()],
                                'value',
                                'label',
                                [{ value: 'true', label: $filter('translate')('global.literals.yes') }, { value: 'false', label: $filter('translate')('global.literals.no') }],
                                false,
                                'radio'
                            ),
                            data
                        );
                        var value = getValue(confValue);

                        if(value) {
                            $scope.form.model[conf.key] = "true";
                        } else {
                            $scope.form.model[conf.key] = "false";
                        }
                        break;
                    case 'NUMBER':
                        $scope.form.addField(
                            conf.key,
                            'annexaInputRow',
                            'col-sm-12',
                            new AnnexaFormlyFieldTemplateOptions(
                                'number',
                                conf[Language.getActiveColumn()],
                                false
                            ),
                            data
                        );
                        $scope.form.model[conf.key] = getValue(confValue);
                        break;
                    case 'CUSTOM':
                        $scope.form.addField(
                            conf.key,
                            'annexaLabelButton',
                            'col-sm-12',
                            new AnnexaFormlyFieldLabelButtonTemplateOptions(
                                'text',
                                conf[Language.getActiveColumn()],
                                'global.literals.configure',
                                undefined,undefined,undefined,undefined,undefined,undefined,
                                $scope[conf.key]
                            ),
                            data
                        )
                        break;
                    case 'LANGUAGE':
                        $scope.form.addField(
                            conf.key + '1',
                            'annexaInputLanguage',
                            'col-sm-12',
                            new AnnexaFormlyFieldLanguageTemplateOptions(
                                conf.key,
                                conf.key,
                                conf[Language.getActiveColumn()],
                                'horizontal',
                                false,
                                false,
                                false,
                                $rootScope.app.languagedef
                            ),
                            data
                        )

                        $scope.form.model[conf.key + '1'] = confValue.language1;
                        $scope.form.model[conf.key + '2'] = confValue.language2;
                        $scope.form.model[conf.key + '3'] = confValue.language3;
                        break;
                }
            });
        });



        var submitConfiguration = function() {
            $scope.form.form.$setSubmitted();

            if($scope.form.form.$valid) {
                var model = $scope.form.model;
                var update = [];

                var pendingLanguage = '{';

                _.forEach(model, function(value, key) {
                    if(!key.startsWith('GROUP')) {
                        var languageKey = false;

                        if (key.endsWith('1')) {
                            pendingLanguage += '"language1":"' + value + '"';
                            languageKey = true;
                        } else if (key.endsWith('2')) {
                            pendingLanguage += ',"language2":"' + value + '"';
                            languageKey = true;
                        } else if (key.endsWith('3')) {
                            pendingLanguage += ',"language3":"' + value + '"}';
                            languageKey = true;
                        }

                        if (languageKey) {
                            key = key.substr(0, key.length - 1);
                        }

                        var conf = $linq(GlobalDataFactory.configuration).singleOrDefault(undefined, "x => x.key == '" + key + "'");

                        if (conf) {
                            switch (conf.frontendType) {
                                case 'TEXT':
                                    conf.value = '{"value":"' + value + '"}';
                                    update.push(conf);
                                    break;
                                case 'BOOLEAN':
                                    conf.value = '{"value":' + value + '}';
                                    update.push(conf);
                                    break;
                                case 'NUMBER':
                                    conf.value = '{"value":' + value + '}';
                                    update.push(conf);
                                    break;
                                case 'LANGUAGE':
                                    if (pendingLanguage.endsWith('}')) {
                                        conf.value = pendingLanguage;
                                        update.push(conf);
                                        pendingLanguage = "{";
                                    }
                                    break;
                            }
                        }
                    }
                });

                AdminFactory.updateConfigurations(update)
                    .then(function (data) {
                        var ok = dialogs.notify(
                            '',
                            $filter('translate')('global.literals.configurationOk'),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                        GlobalDataFactory.configuration = [];
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('configuration')){
                        	CacheFactory.get('globalDataCache').remove('configuration')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        $scope.alerts.push({ msg: 'global.errors.errorConfiguration'})
                });
            }
        };

        $scope.form.addSubmitCallback(submitConfiguration);

        //endregion

    }]);