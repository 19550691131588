/**
 * Created by dpardell on 29/04/2016.
 */
angular
    .module('annexaApp')
    .controller('PresetSignCircuitsController',['$rootScope', '$scope', '$http', '$state', '$filter', 'TableFilter','DTColumnBuilder', 'DTOptionsBuilder','apiAdmin','CommonAdminService','HeaderService','RestService', 'Language', 'CommonAdminModals', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'AdminFactory', '$timeout', 'HelperService', 'GlobalDataFactory', 'CacheFactory', 'DialogsFactory',
    function ($rootScope, $scope, $http, $state, $filter, TableFilter, DTColumnBuilder, DTOptionsBuilder,apiAdmin,CommonAdminService,HeaderService,RestService, Language, CommonAdminModals, AnnexaFormlyFactory, AnnexaModalFactory, AdminFactory, $timeout, HelperService, GlobalDataFactory, CacheFactory, DialogsFactory){
        $scope.showLoadingdiv = false;

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.presetSignCircuits') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton',undefined,'#admin-presetSignCircuit','fa fa-plus','global.commonAdmin.newPresetSignCircuit',undefined,'openNewPresetSignCircuit')
                ];
            }
        });
        HeaderService.changeState($state.current);

        $scope.languageColumn = Language.getActiveColumn();
        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');

        $scope.dtDefPresetSignCircuits = new TableData('sign','PresetSignCircuit','./api/sign/byfilter').setSort([[1, 'asc']]).setSortName('name');

        var nameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
        var dateColumn = new DateColumn($filter,'global.commonAdmin.datatable.createDate');
        var presetSignCircuitActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
            new ActionButton('global.literals.see', 'openPresetSignCircuit([data])', 'fa-eye'),
            [new ActionButton('global.literals.remove', 'deletePresetSignCircuit([data])')]);


        $scope.tablePresetSignCircuits = {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn(nameColumn.getColumn()).withTitle(nameColumn.getTitle()).renderWith(nameColumn.getRender),
                DTColumnBuilder.newColumn('createdDate').renderWith(dateColumn.getRender).withTitle(dateColumn.getTitle()),
                DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(presetSignCircuitActionsColumn.getTitle()).renderWith(presetSignCircuitActionsColumn.getRender).notSortable()
            ],
            filterCall: {},
            filterCallAux : {},
            reLoadInternalData: function(resetPaging){
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = $scope.tfilter.getFilterCallAux();
                this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                this.filterCallAux.langColumn = $scope.languageColumn;
                this.table.reloadData(function callbackInstance(json) {},resetPaging);
            }
        };

        $scope.doSearchFilter=function(queued) {
            $scope.tablePresetSignCircuits.reLoadInternalData(true);
        };

        $scope.openPresetSignCircuit = function (id){
            if(id) {
                RestService.findOne('PresetSignCircuit', id).then(function(data) {
                    var modal = CommonAdminModals.presetSignCircuitEdit;
                    var saveActionFunction = function(index){
                        if(modal.presetSignActionDefinition.presetSignActions[index]) {
                        	modal.presetSignActionDefinition.presetSignActions[index].presetSignCircuit = modal.data;
                        	CommonAdminService.insertOrUpdatePresetSignCircuit(modal.data).then(function (dataUpdated) {
                        		modal.data = JSOG.decode(dataUpdated);
                                angular.forEach(modal.data.presetSignActions,function(value, key){
                                    var aux = HelperService.findFirstElementWhere(AdminFactory.signatoryTypes, 'id', value.signatoryType.id, -1);
                                    if(aux != -1){
                                        value.signatoryType = aux;
                                    }
                                    var aux2 = HelperService.findFirstElementWhere(AdminFactory.signActionTypes, 'id', value.signActionType.id, -1);
                                    if(aux != -1){
                                        value.signActionType = aux2;
                                    }
                                    if(value.user){
                                        var name = value.user.name;
                                        if(value.user.surename1){ name = name + " "+value.user.surename1};
                                        if(value.user.surename2){name = name + " "+value.user.surename2};
                                        value.userSelect = { id: value.user.id, object: {id:value.user.id, name: value.user.name, surename1:value.user.surename1, surename2:value.user.surename2}, value: name };
                                    }
                                });
                                var presetSignActionDefinition = new PresetSignActionsDefinition('', $rootScope.LoggedUser.id, $scope.languageColumn, true, modal.data.presetSignActions, AdminFactory.signatoryTypes, AdminFactory.signActionTypes, saveActionFunction, removeActionFunction);
                                modal.presetSignActionDefinition = presetSignActionDefinition;
                                var a = 0;
                                if(GlobalDataFactory.presetSignCircuit){
                                	GlobalDataFactory.presetSignCircuit.length = 0;
                                }else{
                                	GlobalDataFactory.presetSignCircuit = [];
                                }
                                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                                }
                                GlobalDataFactory.loadGlobalData();
                                modal.presetSignActionDefinitionOriginal = angular.copy(presetSignActionDefinition);
                            }).catch(function (error) {
                            	modal.presetSignActionDefinition = angular.copy(modal.presetSignActionDefinitionOriginal);
                            	DialogsFactory.error($filter('translate')('global.commonAdmin.errors.errorEditPresetSignCircuit'), $filter('translate')('DIALOGS_ERROR_MSG'));
                                var a = 0;
                            });
                        }
                    };

                    var removeActionFunction = function(index){
                    	var idActionSplice = modal.data.presetSignActions[index].id;
                    	modal.data.presetSignActions[index].deleted = true;
                    	modal.data.presetSignActions[index].removedDate = new Date();
                        CommonAdminService.insertOrUpdatePresetSignCircuit(modal.data).then(function (dataUpdated) {
                        	var indexActionSplice = index;
                        	modal.data = JSOG.decode(dataUpdated);
                            angular.forEach(modal.data.presetSignActions,function(value, key){
                                var aux = HelperService.findFirstElementWhere(AdminFactory.signatoryTypes, 'id', value.signatoryType.id, -1);
                                if(aux != -1){
                                    value.signatoryType = aux;
                                }
                                var aux2 = HelperService.findFirstElementWhere(AdminFactory.signActionTypes, 'id', value.signActionType.id, -1);
                                if(aux != -1){
                                    value.signActionType = aux2;
                                }
                                if(value.user){
                                    var name = value.user.name;
                                    if(value.user.surename1){ name = name + " "+value.user.surename1};
                                    if(value.user.surename2){name = name + " "+value.user.surename2};
                                    value.userSelect = { id: value.user.id, object: {id:value.user.id, name: value.user.name, surename1:value.user.surename1, surename2:value.user.surename2}, value: name };
                                }
                                if(value.deleted) {
                                	indexActionSplice = key;
                                }
                            });
                            var presetSignActionDefinition = new PresetSignActionsDefinition('', $rootScope.LoggedUser.id, $scope.languageColumn, true, modal.data.presetSignActions, AdminFactory.signatoryTypes, AdminFactory.signActionTypes, saveActionFunction, removeActionFunction);
                            modal.presetSignActionDefinition = presetSignActionDefinition;
                            var a = 0;
                            modal.presetSignActionDefinition.presetSignActions.splice(indexActionSplice, 1);
                            if(GlobalDataFactory.presetSignCircuit){
                            	GlobalDataFactory.presetSignCircuit.length = 0;
                            }else{
                            	GlobalDataFactory.presetSignCircuit = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                            }
                            GlobalDataFactory.loadGlobalData();
                            modal.presetSignActionDefinitionOriginal = angular.copy(presetSignActionDefinition);
                        }).catch(function (error) {
                        	modal.presetSignActionDefinition = angular.copy(modal.presetSignActionDefinitionOriginal);
                        	DialogsFactory.error($filter('translate')('global.commonAdmin.errors.errorDeletePresetSignCircuit'), $filter('translate')('DIALOGS_ERROR_MSG'));
                            var a = 0;
                        });
                    };

                    var updatePresetSignCircuit = function(val, prop){
                        if(prop != 'language1' && prop != 'language2' && prop != 'language3'){
                            this.data[prop] = val;
                        }else{

                        }
                        CommonAdminService.insertOrUpdatePresetSignCircuit(this.data).then(function (dataUpdated) {
                            var a = 0;
                            if(GlobalDataFactory.presetSignCircuit){
                            	GlobalDataFactory.presetSignCircuit.length = 0;
                            }else{
                            	GlobalDataFactory.presetSignCircuit = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }).catch(function (error) {
                        	DialogsFactory.error($filter('translate')('global.commonAdmin.errors.errorEditPresetSignCircuit'), $filter('translate')('DIALOGS_ERROR_MSG'));
                            var a = 0;
                        });
                    }

                    modal.data = JSOG.decode(data.data);
                    angular.forEach(modal.data.presetSignActions,function(value, key){
                        var aux = HelperService.findFirstElementWhere(AdminFactory.signatoryTypes, 'id', value.signatoryType.id, -1);
                        if(aux != -1){
                            value.signatoryType = aux;
                        }
                        var aux2 = HelperService.findFirstElementWhere(AdminFactory.signActionTypes, 'id', value.signActionType.id, -1);
                        if(aux != -1){
                            value.signActionType = aux2;
                        }
                        if(value.user){
                            var name = value.user.name;
                            if(value.user.surename1){ name = name + " "+value.user.surename1};
                            if(value.user.surename2){name = name + " "+value.user.surename2};
                            value.userSelect = { id: value.user.id, object: {id:value.user.id, name: value.user.name, surename1:value.user.surename1, surename2:value.user.surename2}, value: name };
                        }
                    });
                    var presetSignActionDefinition = new PresetSignActionsDefinition('', $rootScope.LoggedUser.id, $scope.languageColumn, true, modal.data.presetSignActions, AdminFactory.signatoryTypes, AdminFactory.signActionTypes, saveActionFunction, removeActionFunction);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.extra = $scope.tableDocumentType;
                    modal.presetSignActionDefinition = presetSignActionDefinition;
                    modal.presetSignActionDefinitionOriginal = angular.copy(presetSignActionDefinition);
                    modal.updatePresetSignCircuit = updatePresetSignCircuit;
                    modal.callCloseFunction = $scope.doSearchFilter;
                    AnnexaModalFactory.showModal('modaPresetSignCircuitEdit', modal);
                })
            }
        };

        $scope.openNewPresetSignCircuit = function (){
            var saveNewPresetSignCircuit = function(){
                form.form.$setSubmitted();
                if (form.form.$valid) {
                    var valid = true;
                    angular.forEach(form.model.presetSignActions, function(value, key){
                        if(value.actionOrder && value.signatoryType && value.actionOrder && value.signActionType && value.maxDurationDays){
                            if(!value.actionLevel && !value.user){
                                valid = false
                            }
                        }else{
                            valid = false
                        }
                    });
                    if(valid && form.model.presetSignActions.length > 0){
                        CommonAdminService.insertOrUpdatePresetSignCircuit(form.model).then(function(data) {
                            modal.extra.reLoadInternalData(true);
                            if(GlobalDataFactory.presetSignCircuit){
                            	GlobalDataFactory.presetSignCircuit.length = 0;
                            }else{
                            	GlobalDataFactory.presetSignCircuit = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                            }
                            GlobalDataFactory.loadGlobalData();
                            modal.close();
                        }).catch(function(){
                            var b=0;
                        });
                    }
                }
            };

            var savePreset = function(){
                $timeout(function () {
                    angular.element('#smPresetForm' +modal.id).trigger('click');
                })
            };

            var modal = CommonAdminModals.presetSignCircuitNew;
            var data = {row: true,informed: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
            var form = new AnnexaFormly();
            var field1 = form.createField('language1','annexaInputLanguage','col-sm-12',new AnnexaFormlyFieldLanguageTemplateOptions('language','','global.literals.name','horitzontal',true,false,true, $rootScope.app.languagedef),data);
            form.fields.push(field1);
            form.model = {presetSignActions:[] };
            var presetSignActionDefinition = new PresetSignActionsDefinition('', $rootScope.LoggedUser.id, $scope.languageColumn, true, form.model.presetSignActions, AdminFactory.signatoryTypes, AdminFactory.signActionTypes);
            form.addSubmitCallback(saveNewPresetSignCircuit);
            modal.savePreset = savePreset;
            modal.form = form;
            modal.id = 'modalPresetSignCircuitNew'+new Date().getTime();
            modal.presetSignActionDefinition = presetSignActionDefinition;
            modal.extra = $scope.tablePresetSignCircuits;
            modal.noFooter = false;
            modal.annexaFormly = form;
            modal.submitModal = modal.annexaFormly.onSubmit;
            AnnexaModalFactory.showModal(modal.id, modal);
        };

        $scope.deletePresetSignCircuit = function (id){
            CommonAdminService.deletePresetSignCircuit(id).then(function (dataUpdate) {
                $scope.tablePresetSignCircuits.reLoadInternalData(true);
                if(GlobalDataFactory.presetSignCircuit){
                	GlobalDataFactory.presetSignCircuit.length = 0;
                }else{
                	GlobalDataFactory.presetSignCircuit = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                }
                GlobalDataFactory.loadGlobalData();
            }).catch(function () {
            });
        };
    }]);