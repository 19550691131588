angular
    .module('annexaApp')
        .controller('BooskAdminController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'RestService', 'Language', 'HelperService', 'ErrorFactory','AdminFactory', 'DialogsFactory', 'GlobalDataFactory', 'BooksFactory', 'CommonService', 'SecFactory', 'globalModals', 'Upload', '$http',
            function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, RestService, Language, HelperService, ErrorFactory, AdminFactory, DialogsFactory, GlobalDataFactory, BooksFactory, CommonService, SecFactory, globalModals, Upload, $http) {
                $scope.showLoadingdiv = false;
                $scope.languageColumn = Language.getActiveColumn();
                $scope.tfilter = new TableFilter();
                $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');
                $scope.modalBooksAdmin;

                $scope.dtDefBookType = new TableData('books', 'BookType', './api/books_type/byfilter').setSort([[1, 'asc']]).setSortName($scope.languageColumn);

                var nameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
                var bookTypeActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see', 'openBookType([data])', 'fa-eye'),
                    [new ActionButton('global.literals.remove', 'deleteBookType([data])')]);

                $scope.tableBookType = {
                    dtColumns: [
                        DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                        DTColumnBuilder.newColumn(nameColumn.getColumn()).withTitle(nameColumn.getTitle()).renderWith(nameColumn.getRender),
                        DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(bookTypeActionsColumn.getTitle()).renderWith(bookTypeActionsColumn.getRender).notSortable()
                    ],
                    filterCall: $scope.tfilter.getFilterCall(),
                    filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
                    reLoadInternalData: function (resetPaging) {
                        this.filterCall = $scope.tfilter.getFilterCall();
                        this.filterCallAux = $scope.tfilter.getFilterCallAux();
                        this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        this.filterCallAux.langColumn = $scope.languageColumn;
                        this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                    }
                };

                HeaderService.onChangeState($scope, function (message) {
                    if(message.state.name == 'annexa.admin.booktype') {
                        $rootScope.subHeadButtons = [
                            new HeadButtonSearch("#tableFilter"),
                            new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#admin-booktype','fa fa-plus','global.books.admin.titleNew',undefined,'openNewBookType')
                        ];
                    }
                });

                HeaderService.changeState($state.current);

                $scope.doSearchFilter = function() {
                    $scope.tableBookType.reLoadInternalData(true);
                };

                $scope.deleteBookType = function(id) {
                    if(id) {
                    	DialogsFactory.confirm('global.books.admin.deleteBook', 'global.books.admin.deleteBookConfirm')
                        .then(function (data) {
                        	RestService.checkDelete('./api/books_type/check_delete/' + id)
                            .then(function(data) {
                                if(data) {
                                    RestService.delete('./api/books_type/' + id).then(function(data) {
                                            $scope.doSearchFilter();
                                    }).catch(function (error) {
                                    	DialogsFactory.error($filter('translate')('global.books.admin.deleteError'), $filter('translate')('DIALOGS_ERROR_MSG'));
                                    });
                                }else{
                                	DialogsFactory.error($filter('translate')('global.books.admin.deleteErrorBooks'), $filter('translate')('DIALOGS_ERROR_MSG'));
                                }
                            }).catch(function(error) {
                                DialogsFactory.error($filter('translate')('global.books.admin.deleteError'), $filter('translate')('DIALOGS_ERROR_MSG'));
                            });       	
         	           }).catch(function (data) {
         		               //Empty
         		       });
                    }
                };

                $scope.openNewBookType = function () {
                    var modal = angular.copy(CommonAdminModals.bookTypeNew);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.model.modal_body = {column2:{multipleOffice:(($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1)?true:false)}};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = { readOnly: false};
                    modal.extra = $scope.tableBookType;
                    AnnexaFormlyFactory.showModal('modalBookType', modal, $scope.saveNewBookType, false, undefined);
                };

                var getParams = function(modal){
                	if(modal.data.bookTypeFilterElements){
                		if(!modal.dataAux.params){
                    		modal.dataAux.params = {};
                    	}
                    	_.forEach(modal.data.bookTypeFilterElements, function(filterElement){
                    		if(filterElement.value && !modal.dataAux.params[filterElement.alias] ){
                        		 if(_.contains(['registerEntryOffice','entryType','entryDocumentType','entryClassification','template','section', 'type', 'origin', 'docStatus', 'organ', 'sessionType', 'family', 'proposalType'],filterElement.alias) || (_.contains(['state'],filterElement.alias) && modal.data.bookElement == 'DECREE')){
                        			 modal.dataAux.params[filterElement.alias] = {id: filterElement.value};
                        		 }else if(_.contains(['diligenceProfile'],filterElement.alias) || (_.contains(['state'],filterElement.alias) && modal.data.bookElement != 'DECREE')){
                        			 modal.dataAux.params[filterElement.alias] = [];
                        			 var values = JSON.parse(filterElement.value);
                        			 if(values && values.length){
                        				 _.forEach(values, function(valueParam){
                        					 if(valueParam.id){
                        						 var elem = {id:valueParam.id};
                        						 if('diligenceProfile' == filterElement.alias){
                        							 elem = $linq(modal.bookTypeProfiles).firstOrDefault(elem,"x => x.id == "+valueParam.id);
                        						 }else if('state' == filterElement.alias){
                        							 elem = $linq(modal.sessionStates).firstOrDefault(elem,"x => x.id == '"+valueParam.id+"'");
                        						 }
                        						 modal.dataAux.params[filterElement.alias].push(elem);
                        					 }
                        				 });
                        			 }
                        			 
                        		 }else{
                        			 modal.dataAux.params[filterElement.alias] = filterElement.value;
                        		 }
                    		}
                    	});
                    }
                }
                
                $scope.openBookType = function (id) {
                    if(id) {
                    	var replaceDocument = function() {
                            if(this.annexaFormly.form.$valid) {
                                $rootScope.loading(true)
                                var self = this;
                                var data = this.annexaFormly.model.modal_body;

                                RestService.update('./api/books_type/'+self.callerModal.data.id+'/update_attach_document', JSON.stringify(data.file[0]))
                                    .then(function(data) {
                                    	self.callerModal.data.attachTemplate = data.attachTemplate;
                                        $rootScope.loading(false);
                                        self.close();
                                    }).catch(function(error) {
                                        $rootScope.loading(false);
                                        DialogsFactory.error($filter('translate')('global.books.admin.reploceDocumentBookError'), $filter('translate')('DIALOGS_ERROR_MSG'));
                                        console.log(error);
                                })
                            }
                        }

                    	var addEmptyOption = function(list, name){
                    		var options = angular.copy(list);
                        	var index = $linq(options).indexOf("x => x."+name+" == 'global.literals.all'");
                        	if(index < 0){
                        		var option = {id: -1};
                        		option[name] = $filter('translate')('global.literals.empty'); 
                        		options.unshift(option);
                        	}else{
                        		options[index].id = -1;
                        		options[index][name] = $filter('translate')('global.literals.empty');
                        	}
                        	return options;
                    	}
                    	
                    	RestService.findOne('BookType', id)
                            .then(function(data) {
                                var modal = angular.copy(CommonAdminModals.bookTypeEdit);
                                modal.data = JSOG.decode(data.data);
                                modal.dataAux = {};
                                modal.dataAux.bookElementAux = {id:modal.data.bookElement};
                                modal.dataAux.attachDocumentTypesAux = $linq(modal.data.attachDocumentTypes).select("x => x.documentType").toArray();
                                modal.dataAux.bookTypeViewProfilesAux = $linq(modal.data.bookTypeViewProfiles).select("x => x.profile").toArray();
                                modal.dataAux.bookTypeprocessProfilesAux = $linq(modal.data.bookTypeprocessProfiles).select("x => x.profile").toArray();
                                modal.alerts = [];
                                modal.bookElements = new SortedArray(BooksFactory.bookElements, 'name').sort();
                                modal.attachDocumentTypes = new SortedArray(GlobalDataFactory.allDocumentTypes, Language.getActiveColumn()).sort();
                                modal.indexTemplates = new SortedArray(GlobalDataFactory.templates, 'description').sort();
                                modal.procedures = new SortedArray(GlobalDataFactory.procedures, Language.getActiveColumn()).sort();
                                modal.bookTypeProfiles = new SortedArray(GlobalDataFactory.allProfiles, Language.getActiveColumn()).sort();
                                modal.families = addEmptyOption(GlobalDataFactory.familyProcedures,$scope.languageColumn);
                                modal.sessionTypes = addEmptyOption(GlobalDataFactory.sessionTypes, $scope.languageColumn);
                                modal.organs = addEmptyOption(GlobalDataFactory.organs,$scope.languageColumn);
                                modal.sessionStates = angular.copy(SecFactory.sessionStates);
                                _.forEach(modal.sessionStates, function(state){
                    				state.name = $filter('translate')(state.name);
                    			});
                                modal.registerEntryOffices = addEmptyOption(GlobalDataFactory.registerEntryOffices,$scope.languageColumn);
                                modal.entryTypes = addEmptyOption(GlobalDataFactory.entryTypes, 'name');
                                _.forEach(modal.entryTypes, function(entryType){
                                	entryType.name = $filter('translate')(entryType.name);
                            	});
                                modal.registerEntryDocumentTypesInput = addEmptyOption(GlobalDataFactory.registerEntryDocumentTypesInput,$scope.languageColumn);
                                modal.templates = addEmptyOption($linq(GlobalDataFactory.templates).orderBy("x => x." + Language.getActiveColumn()).toArray(), 'description');
                                modal.registerEntryClassificationsInput = addEmptyOption(GlobalDataFactory.registerEntryClassificationsInput,$scope.languageColumn);
                                modal.sections = addEmptyOption(GlobalDataFactory.sections,$scope.languageColumn);
                                modal.documentTypes = addEmptyOption($linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(),$scope.languageColumn);
                                modal.docModules = addEmptyOption(GlobalDataFactory.docModules,$scope.languageColumn);
                                _.forEach(modal.docModules, function(docMod){
                                	docMod.language1 = $filter('translate')(docMod.language1);
                                	docMod.language2 = $filter('translate')(docMod.language2);
                                	docMod.language3 = $filter('translate')(docMod.language3);
                            	});
                                modal.documentStatuses = addEmptyOption(GlobalDataFactory.documentStatuses, 'nameLiteral');
                                modal.documentStatuses = $linq(modal.documentStatuses).orderBy("x => x.orderStatus").toArray();
                            	_.forEach(modal.documentStatuses, function(state){
                            		state.name = $filter('translate')(state.nameLiteral);
                            	});
                            	 modal.decreeStates = addEmptyOption(SecFactory.decreeStates,'name');
                            	 var propTypes = angular.copy(GlobalDataFactory.proposalTypes);
                             	 propTypes = $linq(propTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
                             	 modal.proposalTypes = addEmptyOption(propTypes,$scope.languageColumn);
                                 modal.printLangugaes = function(model){
                                	var langs = '';
                                    angular.forEach(model, function (val, key) {
                                        if(langs != '') {
                                        	langs += ', ';
                                        }
                                        langs += val[$scope.languageColumn];
                                    });

                                    return langs;
                                }
                                modal.printStates = function(model){
                                	var langs = '';
                                    angular.forEach(model, function (val, key) {
                                        if(langs != '') {
                                        	langs += ', ';
                                        }
                                        langs += val.name;
                                    });

                                    return langs;
                                };
                                modal.replaceDocumentModal = function (bookType, callerModal) {
                                	DialogsFactory.confirm('global.books.admin.reploceDocumentBook', 'global.books.admin.reploceDocumentBookConfirm')
                                    .then(function (data) {
	                                    var modalRepDoc = angular.copy(globalModals.uploadFileBase64);
	                                    modalRepDoc.title = 'global.literals.replace';
	                                    modalRepDoc.annexaFormly.model = {};
	                                    modalRepDoc.annexaFormly.model.modal_body = {};
	                                    modalRepDoc.annexaFormly.options = {};
	                                    modalRepDoc.annexaFormly.options.formState = { readOnly: false};
	                                    modalRepDoc.bookType = bookType;
	                                    modalRepDoc.callerModal = callerModal;
	                                    modalRepDoc.annexaFormly.fields[0].fieldGroup[0].data.accept = '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\'';
	                                    AnnexaFormlyFactory.showModal('modalUploadFile', modalRepDoc, replaceDocument, false);
                      	           }).catch(function (data) {
                 		               //Empty
                      	           });
                                }
                                modal.editOnline = function (attachTemplate) {
                                    CommonService.getEditOnlineUrl(attachTemplate).then(function(eou) {
                                        if(eou != null && eou != undefined && eou != ''){
                                            window.location.href=eou;
                                        }
                                    }).catch(function(){
                                    });
                                }
                                modal.download = function(attachTemplate){
                                	$rootScope.loading(true);
                                    $http.get("./api/repodocs/downloadAlfDoc?id="+attachTemplate, {responseType: 'arraybuffer'})
                                        .success(function (data, status, headers) {
                                            var retDownload = {
                                                file: undefined,
                                                fileName: undefined,
                                                linkElement: undefined,
                                                clickEvent: undefined
                                            }
                                            headers = headers();
                                            var contentType = headers['content-type'];
                                            retDownload.file = new Blob([data], {type: contentType});
                                            retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
                                            try {
                                                if (window.navigator.msSaveOrOpenBlob) {
                                                    window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                                                } else {
                                                    retDownload.linkElement = document.createElement('a');
                                                    var url = window.URL.createObjectURL(retDownload.file);
                                                    retDownload.linkElement.setAttribute('href', url);
                                                    retDownload.linkElement.setAttribute("download", retDownload.fileName);
                                                    retDownload.clickEvent = new MouseEvent("click", {
                                                        "view": window,
                                                        "bubbles": true,
                                                        "cancelable": false
                                                    });
                                                    retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                                                }
                                                $rootScope.loading(false);
                                            } catch (e) {
                                                $rootScope.loading(false);
                                            }
                                        }).error(function (error) {
                                            $rootScope.loading(false);
                                    });
                                }
                                modal.languageColumn = Language.getActiveColumn();
                                modal.nameColumn = 'name';
                                modal.multipleOffice = (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1)?true:false);
                                modal.descriptionColumn = 'description';
                                modal.extra = $scope.tableBookType;
                                modal.updateBookType = $scope.updateBookType;
                                modal.updateBookTypeParam = $scope.updateBookTypeParam;
                                getParams(modal);
                                $scope.modalBooksAdmin = modal;
                                AnnexaModalFactory.showModal('modalBookTypeEdit', modal);
                            });
                    }
                };
                
                $scope.updateBookType = function(val, prop) {
                	var self = $scope.modalBooksAdmin;
                    var bookType = angular.copy($scope.modalBooksAdmin.data);
                    self.alerts = [];
                    if(prop == 'bookElement'&& val && (val.id == 'SESSION' || val.id == 'DOSSIER_DOCUMENT')){
                    	bookType[prop] = val.id;
                    	self.data.bookElement = val.id;
                    	self.dataAux.bookElementAux = $linq(self.bookElements).firstOrDefault({id: val.id}, "x => x.id == '"+ val.id+"'");
                    	var addDocTypes = function(){
                    		 if(this.annexaFormly.form.$valid) {
                                 $rootScope.loading(true)
                                 var selfDocType = this;
                                 var dataDocType = this.annexaFormly.model.modal_body.attachDocumentTypes;
                    		  	 var docTypes = [];
     	                    	 if(dataDocType && dataDocType.length > 0){
     	                    		_.forEach(dataDocType, function(item){
     	                    			var docType = $linq(self.documentTypes).firstOrDefault({id: item},"x => x.id == "+item);
     	                    			docTypes.push({documentType: {id: docType.id, language1: docType.language1, language2: docType.language2, language3: docType.language3}});
     	                    		});
     	                    	 }
     	                    	 bookType.attachDocumentTypes = docTypes;
     	                    	 bookType.bookTypeFilterElements = undefined;
                                 RestService.update('./api/books_type/' + bookType.id, bookType).then(function(data) {
     	                            self.extra.reLoadInternalData(true);
     	                            if (self.data){
     	                            	self.data = data;
     	                                if(!self.dataAux){
     	                                	self.dataAux = {};
     	                                }
     		                            self.dataAux.bookElementAux = $linq(self.bookElements).firstOrDefault({id:self.data.bookElement}, "x => x.id == '"+self.data.bookElement+"'");
     		                            self.dataAux.attachDocumentTypesAux = $linq(self.data.attachDocumentTypes).select("x => x.documentType").toArray();
     		                            self.dataAux.bookTypeViewProfilesAux = $linq(self.data.bookTypeViewProfiles).select("x => x.profile").toArray();
     		                            self.dataAux.bookTypeprocessProfilesAux = $linq(self.data.bookTypeprocessProfiles).select("x => x.profile").toArray();
     		                            getParams(self);
     	                            }
     	                           selfDocType.close();
     	                        }).catch(function (error) {
     	                        	DialogsFactory.error(ErrorFactory.getErrorMessage('bookType', 'updateBookType', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
	     	                    });
                             }
                    	}
                    	var modalDocType = angular.copy(CommonAdminModals.bookTypeEditDocType);
                		modalDocType.annexaFormly.model = {};
                		modalDocType.annexaFormly.model.modal_body = {};
                		modalDocType.annexaFormly.options = {};
                		modalDocType.annexaFormly.options.formState = { readOnly: false};
                		AnnexaFormlyFactory.showModal('modalUploadFile', modalDocType, addDocTypes, false);
                    }else{
	                	if(prop == 'bookElement'){
                    		bookType[prop] = val.id;
	                    }else if(prop == 'attachDocumentTypes'){
	                    	var docTypes = [];
	                    	if(val && val.length > 0){
	                    		_.forEach(val, function(item){
	                    			docTypes.push({documentType: {id: item.id, language1: item.language1, language2: item.language2, language3: item.language3}});
	                    		});
	                    	}
	                    	bookType[prop] = docTypes;
	                    }else if (prop == 'bookTypeViewProfiles' || prop == 'bookTypeprocessProfiles') {
	                        bookType[prop] = [];
	                        _.forEach(val, function (item) {
	                            bookType[prop].push({profile: {id: item.id, language1: item.language1, language2: item.language2, language3: item.language3}});
	                        });
	                    }else if(prop == 'indexTemplate' || prop == 'procedure'){
	                    	bookType[prop] = {id: val.id};
	                    } else if(prop != 'language1' && prop != 'language2' && prop != 'language3') {
	                        bookType[prop] = val;
	                    }
	                    bookType.bookTypeFilterElements = undefined;
	                    
	                    RestService.update('./api/books_type/' + bookType.id, bookType)
	                        .then(function(data) {
	                            self.extra.reLoadInternalData(true);
	                            if (self.data){
	                            	self.data = data;
	                                if(!self.dataAux){
	                                	self.dataAux = {};
	                                }
		                            self.dataAux.bookElementAux = $linq(self.bookElements).firstOrDefault({id:self.data.bookElement}, "x => x.id == '"+self.data.bookElement+"'");
		                            self.dataAux.attachDocumentTypesAux = $linq(self.data.attachDocumentTypes).select("x => x.documentType").toArray();
		                            self.dataAux.bookTypeViewProfilesAux = $linq(self.data.bookTypeViewProfiles).select("x => x.profile").toArray();
		                            self.dataAux.bookTypeprocessProfilesAux = $linq(self.data.bookTypeprocessProfiles).select("x => x.profile").toArray();
		                            getParams(self);
	                            }
	                    }).catch(function (error) {
	                       	DialogsFactory.error(ErrorFactory.getErrorMessage('bookType', 'updateBookType', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
	                    });
                    }
                };

                $scope.updateBookTypeParam = function(val, prop) {
                    var self = $scope.modalBooksAdmin;
                    var bookType = angular.copy($scope.modalBooksAdmin.data);
                    self.alerts = [];
                    var param = {}; 
                    if($scope.modalBooksAdmin.data.bookTypeFilterElements){
                    	param = $linq($scope.modalBooksAdmin.data.bookTypeFilterElements).firstOrDefault({alias:prop, value:((val && val.id)?val.id:val), bookType:{id:bookType.id}}, "x => x.alias == '"+prop+"'");
                    }else{
                    	param = {alias:prop, value:((val && val.id)?val.id:val), bookType:{id:bookType.id}}
                    }
                    if(!param.bookType){
                    	param.bookType = {id:bookType.id};
                    }
	        		if(val && Array.isArray(val)){
	        			var valAux = '';
	        			_.forEach(val,function(feVal){
	        				if(feVal.id){
		        				if(isNaN(feVal)){
		        					valAux += ((valAux == '')?'':',')+"{\"id\":\""+feVal.id+"\"}";
		        				}else{
		        					valAux += ((valAux == '')?'':',')+"{\"id\":"+feVal.id+"}";
		        				}
	        				}else{
	        					if(isNaN(feVal)){
		        					valAux += ((valAux == '')?'':',')+"{\"id\":\""+feVal+"\"}";
		        				}else{
		        					valAux += ((valAux == '')?'':',')+"{\"id\":"+feVal+"}";
		        				}
	        				}
	        			});
	        			param.value = valAux;
	        		}else{
	        			if((_.contains(['registerEntryOffice','entryType','entryDocumentType','entryClassification','template','section','type','origin','docStatus','organ','sessionType','family', 'proposalType'],prop) || (_.contains(['state'],prop) && bookType.bookElement == 'DECREE')) && val && val.id == -1){
	        				param.value = undefined;
                		}else{
                			param.value = ((val && val.id)?val.id:val);                		
                		}
	        		}
                    RestService.insert('./api/books_type/update_book_type_filter_element', param)
                        .then(function(data) {
                            self.extra.reLoadInternalData(true);
                            if(!self.data.bookTypeFilterElements){
                            	self.data.bookTypeFilterElements = [];
                            }
                            param = $linq(self.data.bookTypeFilterElements).firstOrDefault(undefined, "x => x.alias == '"+prop+"'");
                            if(param){
                            	param.value = data.value;
                            }else{
                            	self.data.bookTypeFilterElements.push(data);
                            }
                    }).catch(function (error) {
                    	DialogsFactory.error(ErrorFactory.getErrorMessage('bookType', 'updateBookType', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
                    });

                };
                
                $scope.saveNewBookType = function(){
                    if (this.annexaFormly.form.$valid) {
                        var self = this;
                        var model = this.annexaFormly.model.modal_body.column1;
                        var filterElements = this.annexaFormly.model.modal_body.column2;
                        
                        var attachDocumentTypes = [];
                        _.forEach(model.attachDocumentTypes, function(item) {
                        	attachDocumentTypes.push({ documentType: {id: item} });
                        });
                        var bookTypeViewProfiles = [];
                        _.forEach(model.bookTypeViewProfiles, function(item) {
                        	bookTypeViewProfiles.push({ profile: {id: item} });
                        });
                        var bookTypeprocessProfiles = [];
                        _.forEach(model.bookTypeprocessProfiles, function(item) {
                        	bookTypeprocessProfiles.push({ profile: {id: item} });
                        });

                        var bookTypeFilterElements = [];
                        var i = 0; 
                        for(var key in filterElements) {
                        	if(key != 'appAux' && key != 'multipleOffice'){
                        		var addElement = true;
                        		if((_.contains(['registerEntryOffice','entryType','entryDocumentType','entryClassification','template','section','type','origin','docStatus','organ','sessionType','family', 'proposalType'],key) || (_.contains(['state'], key) && model.bookElement == 'DECREE')) && filterElements[key] == -1){
                        			addElement = false;
                        		}
                        		if(addElement){
	                        		var filterElement = {
	                            		"viewOrder":i,
	                            		"value":filterElements[key],
	                    				"alias":key
	                            	};
	                        		if(filterElement.value && Array.isArray(filterElement.value)){
	                        			var val = '';
	                        			_.forEach(filterElement.value,function(feVal){
	                        				if(isNaN(feVal)){
	                        					val += ((val == '')?'':',')+"{\"id\":\""+feVal+"\"}";
	                        				}else{
	                        					val += ((val == '')?'':',')+"{\"id\":"+feVal+"}";
	                        				}
	                        			});
	                        			filterElement.value = val;
	                        		}
	                            	bookTypeFilterElements.push(filterElement);
                        		}
                        	}
                            
                        }
                        
                        var bookType = {
                            language1: model.language1,
                            language2: model.language2,
                            language3: model.language3,
                            bookElement: model.bookElement,
                            attachDocumentTypes: attachDocumentTypes,
                            attachTemplate:((model.file && model.file.length > 0)?JSON.stringify(model.file[0]):undefined),
                            indexTemplate: {id: model.indexTemplate},
                            procedure: {id: model.procedure},
                            bookTypeViewProfiles:bookTypeViewProfiles,
                            bookTypeprocessProfiles:bookTypeprocessProfiles,
                            bookTypeFilterElements:bookTypeFilterElements
                        };

                        RestService.insert('./api/books_type', bookType).then(function(data) {
                            self.extra.reLoadInternalData(true);
                            self.close();
                        }).catch(function(error) {
                        	DialogsFactory.error(ErrorFactory.getErrorMessage('bookType', 'createBookType', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
                        });
                    }
                };
            }]);